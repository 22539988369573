import React, { FunctionComponent, memo } from "react"
import Fade from "react-reveal/Fade"
import Pattern from "@/assets/images/pattern01.svg"
import Wave from "@/assets/images/waves08.png"
import "./style.scss"

const ContactUs: FunctionComponent = function () {
  return (
    <section id="about-hero" className="pb-5 bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container text-center py-5">
        <Fade bottom>
          <h1 className="fw-bold display-5 mt-4">Making beautiful apps.<br />Together.</h1>
        </Fade>
      </div>
      <img className="wave-after w-100" src={Wave} />
    </section>
  )
}

ContactUs.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(ContactUs, arePropsEqual)
