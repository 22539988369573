import React, { FunctionComponent, memo, useCallback, useEffect, useState } from "react"
import Fade from "react-reveal/Fade"
import { Parallax, useParallaxController } from "react-scroll-parallax"
import Ratio from "react-ratio"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import PlayStore from "@/assets/images/playstore01.svg"
import AppStore from "@/assets/images/appstore01.svg"
import Achievement from "@/assets/images/appoftheday.png"
import Pattern from "@/assets/images/pattern03.svg"
import Feat1 from "@/assets/images/prodHighlight01.png"
import Feat2 from "@/assets/images/prodHighlight02.png"
import Feat3 from "@/assets/images/prodHighlight03.png"
import Feat4 from "@/assets/images/prodHighlight04.png"
import Feat5 from "@/assets/images/prodHighlight05.png"
import AppIcon from "@/assets/images/eSoundAppIcon.png"
import "./style.scss"

interface Props {
}

const ESound: FunctionComponent<Props> = function () {

  const [ isVisible1, setIsVisible1 ] = useState(false)
  const [ isVisible2, setIsVisible2 ] = useState(false)

  const visibilityChanged1 = useCallback((value: boolean) => {
    setIsVisible1((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  const visibilityChanged2 = useCallback((value: boolean) => {
    setIsVisible2((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  useEffect(() => {
    updateParallax()
  }, [])

  return (
    <section id="works-esound" className="position-relative">
      <div className="bg position-absolute top-0 bottom-0 end-0 start-0" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <section className="text-white position-relative section-1 pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 d-none d-md-flex d-lg-none justify-content-center flex-column align-items-center pt-5">
              <VisibilitySensor partialVisibility onChange={visibilityChanged1}>
                <Fade when={isVisible1} bottom className="position-relative">
                  <img className="d-block mb-4" width="80" src={AppIcon} />
                  <h1 className="fw-bold display-5 mb-2">eSound Music</h1>
                  <h1 className="lead"><span className="opacity-75">Free music streaming service</span></h1>
                </Fade>
              </VisibilitySensor>
            </div>
            <div className="px-md-5 pt-5 pt-md-3 d-flex flex-column align-items-center align-items-md-start justify-content-center col-md-6 text-center text-md-start">
              <VisibilitySensor partialVisibility onChange={visibilityChanged1}>
                <Fade when={isVisible1} bottom className="position-relative">
                  <div className="d-block d-md-none d-lg-block">
                    <img className="d-block mb-4 mx-auto mx-md-0" width="80" src={AppIcon} />
                    <h1 className="fw-bold display-5 mb-2">eSound Music</h1>
                    <h1 className="lead mb-4"><span className="opacity-75">Free music streaming service</span></h1>
                  </div>
                  <p className="mb-3 text-start mt-4">eSound is a music streaming service used by millions of users every day.
                  This is the best music experience we could imagine in an app for mobile, desktop, watch and web.
                  We have taken care of the details as much as possible, created a solid and smooth user experience,
                  inserted dozens and dozens of secondary and unique features, which our users appreciate very much.</p>
                </Fade>
              </VisibilitySensor>
            </div>
            <div className="col-md-6 position-relative me-auto d-flex align-items-end">
              <Ratio ratio={0.9} className="w-100 position-relative overflow-hidden">
                <Parallax className="w-100 h-100" translateY={[ 20, -20 ]}>
                  <img className="position-relative feat-5" src={Feat5} onLoad={updateParallax} />
                </Parallax>
              </Ratio>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="card border-0 overflow-hidden">
                <div className="card-body p-0 d-flex flex-row justify-content-around justify-content-md-between">
                  <div className="p-3 d-flex flex-row flex-shrink-0">
                    <img className="d-none d-xl-block" height="50" src={Achievement} />
                    <div className="d-none d-xl-block bg-secondary br mx-4 opacity-25" />
                    <a href="https://play.google.com/store/apps/details?id=com.esound">
                      <img className="mx-2" height="50" src={PlayStore} />
                    </a>
                    <a href="https://apps.apple.com/app/esound-music-musica-mp3/id1224933860">
                      <img className="mx-2" height="50" src={AppStore} />
                    </a>
                  </div>
                  <a href="https://esound.app" className="highlight d-none d-md-flex px-xl-5 align-items-center justify-content-center text-decoration-none">
                    <h5 className="lead fw-bold mx-5 mb-0 text-white">Find out more on the website!</h5>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="text-white position-relative section-2 py-5">
        <div className="container">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-7 position-relative me-auto">
              <Parallax className="w-100 h-100" translateY={[ 30, -30 ]}>
                <Fade when={isVisible2} bottom delay={250} className="position-relative">
                  <img className="position-relative feat-3" src={Feat3} onLoad={updateParallax} />
                </Fade>
                <Fade when={isVisible2} bottom className="position-relative">
                  <img className="position-absolute feat-4" src={Feat4} onLoad={updateParallax} />
                </Fade>
              </Parallax>
            </div>
            <div className="col-md-5 px-md-5 pb-3 pt-5 pt-md-3 d-flex flex-column align-items-center align-items-md-start justify-content-center text-center text-md-start">
              <VisibilitySensor partialVisibility onChange={visibilityChanged2}>
                <Fade when={isVisible1} bottom className="position-relative">
                  <h3 className="fw-bold display-6 mb-3">Your music, <span className="text-primary">without<br />limits</span>.</h3>
                  <p className="mb-3 mt-4">In terms of music, albums, playlists and genres, eSound pretty much has it all.
                  Users can explore all the music they love, save and organize it however they want.
                  They can create playlists, import other songs and listen to their favourite music on any device, mobile or desktop,
                  without any limit.</p>
                </Fade>
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </section>
      <section className="text-white position-relative section-3 pt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6 px-md-5 p-3 d-flex flex-column align-items-center align-items-md-start justify-content-center text-center text-md-start">
              <VisibilitySensor partialVisibility onChange={visibilityChanged2}>
                <Fade when={isVisible1} bottom className="position-relative">
                  <h3 className="fw-bold display-6 mb-3">Playlists, genres, social.<br /><span className="text-primary">Everything</span>!</h3>
                  <p className="mb-3 mt-4">eSound also learns the tastes of users and based on these it generates automatic playlists, daily mixes and recommendations for them.
                  eSound also offers a great social experience: you can share songs, albums and playlists with your friends or see their favorite profiles, songs and artists.</p>
                </Fade>
              </VisibilitySensor>
            </div>
            <div className="col-md-6 position-relative me-auto">
              <Parallax className="w-100 h-100" translateY={[ 30, -30 ]}>
                <Fade when={isVisible1} bottom className="position-relative">
                  <img className="position-relative feat-2" src={Feat2} onLoad={updateParallax} />
                </Fade>
                <Fade when={isVisible1} bottom delay={250} className="position-relative">
                  <img className="position-absolute feat-1" src={Feat1} onLoad={updateParallax} />
                </Fade>
              </Parallax>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

ESound.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(ESound, arePropsEqual)
