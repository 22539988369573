import React, { FunctionComponent, memo } from "react"
import Fade from "react-reveal/Reveal"
import { Entypo } from "react-web-vector-icons"
import Pattern from "@/assets/images/pattern01.svg"
import { HashLink } from "react-router-hash-link"
import Wave from "@/assets/images/waves18.png"
import "./style.scss"

const ContactUs: FunctionComponent = function () {
  return (
    <section id="careers-hero" className="py-5 bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container text-center pt-5 d-flex justify-content-center align-items-center flex-column h-100">
        <Fade bottom>
          <h1 className="fw-bold display-5">Making beautiful apps.<br />Together.</h1>
          <HashLink to="/careers#careers-positions" className="btn btn-primary bg-multicolor border-0 text-white ps-3 pe-4 py-2 mt-5 rounded-pill fw-bold">
            <div className="d-flex align-items-center">
              <Entypo
                name="chevron-down"
                color="white"
                size={25}
              />
              <span className="ps-3">
                Open positions
              </span>
            </div>
          </HashLink>
        </Fade>
      </div>
      <div className="wave-container">
        <img className="wave-after w-100" src={Wave} />
      </div>
    </section>
  )
}

ContactUs.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(ContactUs, arePropsEqual)
