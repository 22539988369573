import { ThemeInterface } from './interface'

export default {
  id: "default",
  colors: {

    statusBar: "dark-content",

    primary: "#9158AA",
    primaryOpaque: "#a68fb0",
    primaryDarker: "#723b8a",

    gold: "#F6B601",

    background: "#ebebeb",
    backgroundLight: "white",
    backgroundOpaque: "rgba(190, 190, 190, 0.3)",

    border: "gray",
    borderLight: "#e0e0e0",

    backgroundDark: "#1E1F20",
    backgroundDarkDarker: "#141414",

    text: "black",
    textSecondary: "#8F92A1",

    textOnBackground: "white",
    textOnBackgroundOpaque: "rgba(255, 255, 255, 0.65)",
    textOnBackgroundSecondary: "#9795ad",
    textOnBackgroundSecondaryLight: "#d7d5e3",

    navigationBackground: "#1E1F20",
    navigationText: "white",

  }
} as ThemeInterface
