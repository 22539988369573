import React, { FunctionComponent, memo, useCallback, useEffect, useRef } from "react"
import { exaUrl } from "@/helpers/constants/constants"
import Fade from "react-reveal/Fade"
import WaveAfter from "@/assets/images/waves10.png"
import WaveBefore from "@/assets/images/waves17.png"
import * as signalR from "@microsoft/signalr"
import { emitEvent, onEvent } from "@/helpers/events/events"
import GeomapIllustration from "@/assets/images/geomap.svg"
import Wave1 from "@/assets/images/wave01.svg"
import Wave2 from "@/assets/images/wave02.svg"
import Ship from "@/assets/images/ship.svg"
import Plane from "@/assets/images/plane.svg"
import p5 from "p5"
import "./style.scss"

interface Props {}

const Geousers: FunctionComponent<Props> = () => {

  const canvasRef = useRef<any>(null)
  const canvas = useRef<any>(null)
  const connection = useRef<signalR.HubConnection | null>(null)

  const sketch = useCallback((p) => {
    // let backgroundImage: any = undefined
    let events: Array<Event> = []
    let colors: Array<Array<number>> = [
      [ 252, 20, 116 ],
      [ 27, 195, 251 ],
      [ 252, 164, 12 ]
    ]

    let degreesToRadians = function (degrees: number): number {
      return (degrees * p.PI) / 180;
    }

    let latLonToOffsets = function(lat: number, long: number): {
      x: number,
      y: number
    } {
      const FE = 180; // false easting
      const radius = p.width / (2 * p.PI);

      const latRad = degreesToRadians(lat);
      const lonRad = degreesToRadians(long + FE);

      const x = lonRad * radius;

      const yFromEquator = radius * p.log(p.tan(p.PI / 4 + latRad / 2));

      const y = p.height / 2 - yFromEquator;

      return { x, y };
    }

    class Event {
      age: number
      color: Array<number>
      pos: { x: number, y: number }

      constructor(lat: number, long: number) {
        this.pos = latLonToOffsets(lat, long)
        this.pos.y = this.pos.y * 0.99 + 156
        this.pos.x = this.pos.x * 0.99 - 30
        this.color = colors[p.round(p.random(0, colors.length - 1))]
        this.age = 0
      }

      update () {
        this.age += 1;
      }

      show () {
        if(this.age >= 40)
          return
        const opacity = p.map(this.age, 0, 40, 255, 0)
        p.fill(this.color[0], this.color[1], this.color[2], opacity)
        p.noStroke()
        const { x, y } = this.pos
        p.circle(x, y, 4)
        p.circle(x, y, this.age)
      }
    }

    function appendEvent (lat: number, long: number) {
      events.push(new Event(lat, long))
      events = events.filter((event) => (event.age <= 40))
    }

    p.preload = function () {
      // backgroundImage = p.loadImage("/images/illustrations/geomap.png")
      p.frameRate(45);
    }

    p.setup = function () {
      p.createCanvas(800 * 1.51, 800)
      onEvent("ReceiveSession", ({ lat, long }) => appendEvent(lat, long))
    }

    p.draw = function () {
      p.clear()
      // p.background('#ffffff')
      // p.tint(0, 0, 0, 0)
      // backgroundImage && p.image(backgroundImage, 0, 0, p.width, p.height)
      p.noTint()
      for (var i = 0; i < events.length; i++) {
        events[i].update()
        events[i].show()
      }
    }
  }, [])

  useEffect(() => {
    connection.current = new signalR.HubConnectionBuilder()
      .withUrl(exaUrl + "/public-realtime")
      .build()

    connection.current.on("ReceiveSession", (lat, long) => {
      if((typeof document.hasFocus !== "function") || !document.hasFocus())
        return
      emitEvent("ReceiveSession", { lat, long })
    })

    connection.current.start()

    if(!canvas.current)
      canvas.current = new p5(sketch, canvasRef.current)

    /*setTimeout(() => {
      emitEvent("ReceiveSession", { lat: 45.464664, long: 9.188540 }) // Milan, Italy
      emitEvent("ReceiveSession", { lat: -34.6037232, long: -58.3815931 }) // Milan, Italy
      emitEvent("ReceiveSession", { lat: -33.9258400, long: 18.4232200 }) // City of Cape, South Africa
      emitEvent("ReceiveSession", { lat: 82.508453, long: -62.410526 }) // Alert, Canada
      emitEvent("ReceiveSession", { lat: 35.6895, long: 139.69171 }) // Tokyo, Japan
      emitEvent("ReceiveSession", { lat: 59.913869, long: 10.752245 }) // Oslo, Norway
      emitEvent("ReceiveSession", { lat: 43.10562, long: 131.87353 }) // Vladivostok, Russia
      emitEvent("ReceiveSession", { lat: 58.305801, long: -134.433304 }) // Juneau, Alaska
    }, 600)*/

    return () => {
      connection.current && connection.current.off("ReceiveSession")
      connection.current && connection.current.stop()
    }
  }, [])

  return (
    <section id="home-geousers" className="position-relative bg-light">
      <img className="wave-before w-100" src={WaveBefore} />
      <div className="container text-center pt-3 pb-5 pt-md-5">
        {/*<div className="backgroundOverlay" >
          <div className="blackOverlay"/>
        </div>*/}
        <Fade bottom>
          <h1 className="fw-bold display-5 mt-4">Our family<br />in realtime.</h1>
        </Fade>
        <div className="mt-5 w-100 map position-relative d-flex align-items-center justify-content-center">

          <Fade bottom>
            <img className="position-absolute decoration-1" src={Wave1} />
            <img className="position-absolute decoration-2" src={Wave1} />
            <img className="position-absolute decoration-3" src={Wave1} />
            <img className="position-absolute decoration-4" src={Wave1} />
            <img className="position-absolute decoration-5" src={Plane} />
            <img className="position-absolute decoration-6" src={Ship} />
            <img className="position-absolute decoration-7" src={Wave2} />
            <img className="position-absolute decoration-8" src={Wave2} />
            <img className="position-absolute decoration-9" src={Plane} />
            <img className="position-absolute decoration-10" src={Wave2} />
            <img className="position-absolute decoration-11" src={Ship} />
          </Fade>

          <img className="map-bg" src={GeomapIllustration} />
          <div className="map-canvas" ref={canvasRef} />
        </div>
      </div>
      <img className="wave-after w-100" src={WaveAfter} />
    </section>
  )
}

Geousers.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Geousers, arePropsEqual)
