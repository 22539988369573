import React, { FunctionComponent, memo, useState, useEffect } from "react"
import DOC from './PRIVACY_POLICY.md'
import ReactMarkdown from 'react-markdown'
import "./style.scss"

const PrivacyPage: FunctionComponent = function () {
  
  let [ content, setContent ] = useState("")

  useEffect(()=> {
    fetch(DOC.toString())
    .then((res) => res.text())
    .then((md) => setContent(md))
  }, [])

  return (
    <div id="privacyPage" className="bg-white">
      <div className="container position-relative py-5 mt-5">
        <h1 className="fw-bold display-5 mt-5">Privacy Policy</h1>
        <ReactMarkdown children={content} className="py-5" />
      </div>
    </div>
  )
}

PrivacyPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(PrivacyPage, arePropsEqual)
