import React, { FunctionComponent, memo, useCallback, useState } from "react"
import Mountains from "@/assets/images/mountains02.svg"
import Pattern from "@/assets/images/pattern07.svg"
import Fade from "react-reveal/Fade"
import Wave from "@/assets/images/waves19.png"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import "./style.scss"

const Values: FunctionComponent = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="careers-values" className="bg-light py-5 position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container position-relative text-black ">
          <div className="row">
            <div className="col-12 col-md-6 px-md-5 mt-5 d-flex align-items-center align-items-md-start justify-content-center flex-column">
              <Fade bottom when={isVisible} exit={false}>
                <h1 className="fw-bold text-center text-md-start">Work on <span className="text-multicolor">Great</span> products</h1>
                <p className="mt-2 mt-md-4 lead text-start">Our apps are used and loved by millions of people all around the world. Your ideas and work directly affect users and make a big impact.</p>
              </Fade>
            </div>
            <div className="col-12 col-md-6 px-md-5 mt-5 d-flex align-items-center align-items-md-start justify-content-center flex-column">
              <Fade bottom when={isVisible} delay={200} exit={false}>
                <h1 className="fw-bold text-center text-md-start">Grow at <span className="text-multicolor">360</span> degrees</h1>
                <p className="mt-2 mt-md-4 lead text-start">We want you to grow exceptionally fast. You will be exposed to a wide variety of interesting challenges, solving tech and business problems and go behind your limits.</p>
              </Fade>
            </div>
            <div className="col-12 col-md-6 px-md-5 mt-5 d-flex align-items-center align-items-md-start justify-content-center flex-column">
              <Fade bottom when={isVisible} delay={400} exit={false}>
                <h1 className="fw-bold text-center text-md-start">Work with <span className="text-multicolor">Exceptional</span> people</h1>
                <p className="mt-2 mt-md-4 lead text-start">We strive to attract amazing talent, we are a very young and small, fast team. We have fun together while creating great products.</p>
              </Fade>
            </div>
            <div className="col-12 col-md-6 ps-md-0 pe-md-5 mt-5 d-flex align-items-center align-items-md-start justify-content-center flex-column">
              <Fade bottom when={isVisible} delay={200} exit={false}>
                <img className="w-100" src={Mountains} />
              </Fade>
            </div>
          </div>
        </div>
      </VisibilitySensor>
      <img className="wave-after w-100" src={Wave} />
    </section>
  )
}

Values.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Values, arePropsEqual)
