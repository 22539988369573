import React, { FunctionComponent, memo, useCallback, useEffect } from "react"
import Fade from "react-reveal/Fade"
import { useParallaxController } from "react-scroll-parallax"
import Pattern from "@/assets/images/pattern01.svg"
import Device1 from "@/assets/images/device04.png"
import Device2 from "@/assets/images/device05.png"
import Device3 from "@/assets/images/device06.png"
import Device4 from "@/assets/images/device07.png"
import Device5 from "@/assets/images/device08.png"
import { Entypo } from "react-web-vector-icons"
import { HashLink } from "react-router-hash-link"
import "./style.scss"

const Hero: FunctionComponent = function () {

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  useEffect(() => {
    window.addEventListener("load", updateParallax)
    return () => {
      window.removeEventListener("load", updateParallax)
    }
  }, [])

  return (
    <section id="home-apps" className="bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container position-relative text-center h-100 d-flex flex-column align-items-center justify-content-center">
        <Fade bottom>
          <h1 className="fw-bold display-5">Making the next<br />level apps.</h1>
          <HashLink to="#home-counter" className="btn btn-primary bg-multicolor border-0 text-white ps-3 pe-4 py-2 mt-5 mb-5 rounded-pill fw-bold">
            <div className="d-flex align-items-center">
              <Entypo
                name="chevron-down"
                color="white"
                size={25}
              />
              <span className="ps-3">
                Explore now
              </span>
            </div>
          </HashLink>
        </Fade>
        <div className="position-absolute device1">
          <Fade delay={200} duration={800} bottom>
            <img className="img-fluid w-100" src={Device1} />
          </Fade>
        </div>
        <div className="position-absolute device2">
          <Fade delay={200} duration={1000} bottom>
            <img className="img-fluid w-100" src={Device2} />
          </Fade>
        </div>
        <div className="position-absolute device3">
          <Fade delay={200} duration={900} bottom>
            <img className="img-fluid w-100" src={Device3} />
          </Fade>
        </div>
        <div className="position-absolute device4">
          <Fade delay={200} duration={900} bottom>
            <img className="img-fluid w-100" src={Device4} />
          </Fade>
        </div>
        <div className="position-absolute device5">
          <Fade delay={200} duration={900} bottom>
            <img className="img-fluid w-100" src={Device5} />
          </Fade>
        </div>
      </div>
    </section>
  )
}

Hero.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Hero, arePropsEqual)
