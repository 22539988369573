import React, { FunctionComponent, memo, useMemo, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { FontAwesome, MaterialIcons } from "react-web-vector-icons"
import Fade from "react-reveal/Fade"
import Submit from "./submit/Submit"
import Wave from "@/assets/images/waves04.png"
import positions from "@/assets/data/careerPositions"
import "./style.scss"

type RouteParams = {
  id: string
}

const CareerPage: FunctionComponent = function () {

  const { id: positionId } = useParams<RouteParams>()

  const position = useMemo(() => positionId && positions[positionId], [ positionId ])

  if(!position || !positionId)
    return <Navigate to="/error/notFound" />

  return (
    <div id="careerPage">
      <section id="career-header" className="pt-5 bg-white" style={{
        backgroundImage: `url("/images/backgrounds/${position.backgroundName}")`
      }}>
        <div className="container h-100 d-flex flex-column align-items-center justify-content-center position-relative py-5 text-center text-white">
          <Fade bottom exit={false}>
            <h1 className="display-4 fw-bold mt-0 mt-md-5"><span className="opacity-50">We are looking for a special</span><br />{position.title}</h1>
          </Fade>
          <h5 className="desc mt-5 align-items-center d-flex opacity-75 flex-column flex-md-row">
            <Fade delay={500} bottom exit={false}>
              { !!position.level && <span className="d-flex align-items-center me-3 mt-3 mt-md-0">
                <FontAwesome
                  name="shield"
                  color="white"
                  size={20}
                />
                <span className="ms-2">{position.level}</span>
              </span> }
            </Fade>
            <Fade delay={800} bottom exit={false}>
              { !!position.location && <span className="d-flex align-items-center mt-3 mt-md-0">
                <MaterialIcons
                  name="location-on"
                  color="white"
                  size={20}
                />
                <span className="ms-2">{position.location}</span>
              </span> }
            </Fade>
          </h5>
        </div>
      </section>
      <section className="bg-white position-relative">
        <img className="wave-before w-100" src={Wave} />
        <Fade bottom duration={2000} exit={false}>
          <div id="career-desc" className="container pt-5 position-relative text-center">
            { position.description?.map((desc, i) => (
              <div key={i} className="pb-5 text-start">
                { !!desc.title && <h3 className="fw-bold">{desc.title}</h3> }
                { !!desc.paragraph && <p className="text-secondary lead">{desc.paragraph}</p> }
                { !!desc.list && <ul className="list-group list-group-flush mt-3">
                  { desc.list?.map((row, j) => (
                    <li key={j} className="list-group-item text-secondary px-0 border-0 d-flex">
                      <span className="text-primary pe-3" style={{
                        paddingTop: "0.1rem"
                      }}>&#x2022;</span>
                      <div>
                        { !!row.title && <span className="fw-bold text-black lead">{row.title}: </span> }
                        {row.paragraph}
                      </div>
                    </li>
                  ))}
                </ul> }
              </div>
            ))}
          </div>
        </Fade>
      </section>
      <Submit id={positionId} />
    </div>
  )
}

CareerPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(CareerPage, arePropsEqual)
