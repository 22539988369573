import React, { FunctionComponent, memo, useCallback, useMemo, useState } from "react"
import positions from "@/assets/data/careerPositions"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import { FontAwesome, MaterialIcons } from "react-web-vector-icons"
import Fade from "react-reveal/Fade"
import "./style.scss"
import { Link } from "react-router-dom"
import Submit from "../../careerPage/submit/Submit"

interface Props {}

const Positions: FunctionComponent<Props> = function (props) {

  const [ isVisible, setIsVisible ] = useState(false)
  const positionsList = useMemo(() => Object.values(positions), [])

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="careers-positions" className="bg-white">
      <VisibilitySensor delayedCall partialVisibility onChange={visibilityChanged}>
        <div className="container position-relative pb-5">
          <h1 className="fw-bold display-5 mb-5 text-center">We want you! :D</h1>
          <Fade when={isVisible} cascade bottom exit={false}>
            <ol className="list-group list-group-flush">
              { positionsList.map((position) => {
                if(position.id === "custom-position")
                  return null
                return (
                  <Link
                    key={position.id}
                    to={`/careers/${position.id}`}
                    className="position list-group-item d-flex justify-content-between align-items-start text-white py-4 p-3 p-sm-4 p-md-5 mb-4 flex-column flex-md-row"
                    style={{
                      backgroundImage: `url("/images/backgrounds/${position.backgroundName}")`
                    }}
                  >
                    <div className="ms-2 me-auto">
                      <h1 className="title fw-bold">{position.title}</h1>
                      <h6 className="desc mt-0 mt-md-3 align-items-md-start align-items-md-center d-flex flex-column flex-md-row">
                        { !!position.level && <span className="d-flex align-items-center me-3 opacity-75 mt-3 mt-md-0 ps-1 ps-md-0">
                          <FontAwesome
                            name="shield"
                            color="white"
                            size={20}
                          />
                          <span className="ms-2">{position.level}</span>
                        </span> }
                        { !!position.location && <span className="d-flex align-items-center opacity-75 mt-3 mt-md-0">
                          <MaterialIcons
                            name="location-on"
                            color="white"
                            size={20}
                          />
                          <span className="ms-2">{position.location}</span>
                        </span> }
                      </h6>
                    </div>
                    <Link
                      to={`/careers/${position.id}`}
                      className="btn btn-light btn-lg flex-shrink-0 mt-4 mt-md-0 rounded-pill fw-bold bg-white px-4 align-self-center"
                    >Apply now</Link>
                  </Link>
                )
              })}
              <div className="mt-0 mt-lg-5" />
              <Submit
                id="custom-position"
                title="Have a custom idea for your position?"
                desc="Apply now! We can't wait to meet you."
              />
            </ol>
          </Fade>
        </div>
      </VisibilitySensor>
    </section>
  )
}

Positions.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Positions, arePropsEqual)
