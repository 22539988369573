import { EventRegister } from "react-events-listeners"

export const emitEvent = function (event: string, data?: any) {
  EventRegister.emit(event, data)
}

export const onEvent = function (event: string, method: any) {
  return EventRegister.on(event, method)
}

export const removeEvent = function (event: string) {
  return EventRegister.rm(event)
}
