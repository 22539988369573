import React, { FunctionComponent, memo } from "react"
import Hero from "./hero/Hero"
import "./style.scss"

interface Props {

}

const NotFoundPage: FunctionComponent<Props> = function () {

  return (
    <div id="notFoundPage">
      <Hero />
    </div>
  )
}

NotFoundPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(NotFoundPage, arePropsEqual)
