import React, { FunctionComponent, memo, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import Background from "@/assets/images/bg-waves02.svg"
import Wave from "@/assets/images/waves09.png"
import Tree1 from "@/assets/images/tree01.svg"
import Tree2 from "@/assets/images/tree02.svg"
import Tree3 from "@/assets/images/tree03.svg"
import Home from "@/assets/images/home.svg"
import Fade from "react-reveal/Fade"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import "./style.scss"

interface Props {}

const Careers: FunctionComponent<Props> = function (props) {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="careers-careers" className="py-5 d-flex align-items-center position-relative">
      <div className="position-absolute top-0 start-0 end-0 bottom-0 background" style={{
        backgroundImage: `url('${Background}')`
      }} />
      <div className="container position-relative">
        <div className="row">
          <VisibilitySensor partialVisibility onChange={visibilityChanged}>
            <div className="col-md-6 mx-auto d-flex flex-column align-items-center justify-content-center text-center text-white pb-5">
              <h1 className="fw-bold display-5 mb-2">Careers</h1>
              <h6 className="lead mt-3">Join us and create big products, used by millions.<br />Make a difference.</h6>
              <Link to="/careers" className="btn btn-dark btn-lg px-5 py-2 mt-5 rounded-pill">
                Apply now
              </Link>
            </div>
          </VisibilitySensor>
        </div>
      </div>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree1" src={Tree1} />
      </Fade>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree2" src={Tree2} />
        <img className="position-absolute home" src={Home} />
      </Fade>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree3" src={Tree3} />
      </Fade>
      <img className="wave-after w-100" src={Wave} />
    </section>
  )
}

Careers.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Careers, arePropsEqual)
