interface CareerPosition {
  id: string,
  title: string,
  level?: string,
  location?: string,
  backgroundName: string,
  backgroundColor: string,
  backgroundColorLight: string,
  description?: Array<{
    title?: string,
    paragraph?: string,
    list?: Array<{
      title?: string,
      paragraph?: string
    }>
  }>
}

export default <{
  [id: string]: CareerPosition
}> {
  "custom-position": {
    "id": "custom-position",
    "title": "Custom position",
    "backgroundName": "background1.svg",
    "backgroundColor": "#210d65",
    "backgroundColorLight": "#43407f",
    "location": "Work from home! Literally always :)",
  },
  "react-developer": {
    "id": "react-developer",
    "title": "React developer",
    "level": "Junior or Senior",
    "backgroundName": "background2.svg",
    "backgroundColor": "#d42494",
    "backgroundColorLight": "#aa1999",
    "location": "Work from home! Literally always :)",
    "description": [{
      "title": "",
      "paragraph": "We are looking for a talented React and front-end developer to join our development team at Spicy Sparks. You will work on very big products and apps that are used by millions of users every day and make an impact, all in a very young environment."
    }, {
      "title": "Key responsibilities:",
      "paragraph": "",
      "list": [{
        "title": "Development",
        "paragraph": "You will work on the development of the web/desktop apps (and eventually on mobile too), such as eSound Music."
      }, {
        "title": "Engineering",
        "paragraph": "You will contribute to the definition of the new features from an high-level view and you will help define the architecture of our apps."
      }, {
        "title": "QA & Testing",
        "paragraph": "You will define the quality requirements, design and implement automatic tests, and help shape our codebase."
      }]
    }, {
      "title": "Our ideal candidate is:",
      "paragraph": "",
      "list": [{
        "title": "Experienced",
        "paragraph": "You have already contributed to the development of different React projects at scale. You have a good knowledge of React, Hooks, Typescript, Redux and Immutable.JS. You know how to design and develop clean UIs and smooth animations. It's a big plus if you know well Webpack, Babel, NPM and Git."
      }, {
        "title": "Passionate",
        "paragraph": "You love the apps world. You get every app that you find just to understand how it works and how it’s built."
      }, {
        "title": "Scrupulous",
        "paragraph": "You are details oriented, you strive to code the most efficient/generic code or the perfect UI."
      }, {
        "title": "Organized",
        "paragraph": "You like to keep your code neat and clean. You know how to use Git to work on a multi-developer project."
      }, {
        "title": "Humble",
        "paragraph": "You are open to receiving feedbacks about your work and you are always eager to collaborate and share ideas."
      }]
    }, {
      "title": "What we offer:",
      "paragraph": "",
      "list": [{
        "title": "Huge influence",
        "paragraph": "Your code will run on millions of devices worldwide. You will directly influence the performance of our business."
      }, {
        "title": "Young work environment",
        "paragraph": "You will work in a young, fast, and flexible environment. We have fun together while creating great products."
      }, {
        "title": "Fully remote and flexible",
        "paragraph": "You will work fully remote with good flexibility on working hours, working based on goals."
      }]
    }]
  },
  "react-native-developer": {
    "id": "react-native-developer",
    "title": "React Native developer",
    "level": "Junior or Senior",
    "backgroundName": "background3.svg",
    "backgroundColor": "#214ce2",
    "backgroundColorLight": "#2596be",
    "location": "Work from home! Literally always :)",
    "description": [{
      "title": "",
      "paragraph": "We are looking for a talented React Native developer to join our development team at Spicy Sparks. You will work on very big products and apps that are used by millions of users every day and make an impact, all in a very young environment."
    }, {
      "title": "Key responsibilities:",
      "paragraph": "",
      "list": [{
        "title": "Development",
        "paragraph": "You will work on the development of the Android and iOS apps (with React Native and Native frameworks), such as eSound Music."
      }, {
        "title": "Engineering",
        "paragraph": "You will contribute to the definition of the new features from an high-level view and you will help define the architecture of our apps."
      }, {
        "title": "QA & Testing",
        "paragraph": "You will define the quality requirements, design and implement automatic tests, and help shape our codebase."
      }]
    }, {
      "title": "Our ideal candidate is:",
      "paragraph": "",
      "list": [{
        "title": "Experienced",
        "paragraph": "You have already contributed to the development of different React Native apps at scale. You have a good knowledge of React/React Native, Hooks, Typescript, Redux and Immutable.JS. You know also some of the native technologies, such as Swift/Objective C and Java/Kotlin."
      }, {
        "title": "Passionate",
        "paragraph": "You love the mobile apps world. You download every app that you find just to understand how it works and how it’s built."
      }, {
        "title": "Scrupulous",
        "paragraph": "You are details oriented, you strive to code the most efficient/generic code or the perfect UI."
      }, {
        "title": "Organized",
        "paragraph": "You like to keep your code neat and clean. You know how to use Git to work on a multi-developer project."
      }, {
        "title": "Humble",
        "paragraph": "You are open to receiving feedbacks about your work and you are always eager to collaborate and share ideas."
      }]
    }, {
      "title": "What we offer:",
      "paragraph": "",
      "list": [{
        "title": "Huge influence",
        "paragraph": "Your code will run on millions of devices worldwide. You will directly influence the performance of our business."
      }, {
        "title": "Young work environment",
        "paragraph": "You will work in a young, fast, and flexible environment. We have fun together while creating great products."
      }, {
        "title": "Fully remote and flexible",
        "paragraph": "You will work fully remote with good flexibility on working hours, working based on goals."
      }]
    }]
  },
  "backend-developer": {
    "id": "backend-developer",
    "title": "Backend developer",
    "level": "Junior or Senior",
    "backgroundName": "background4.svg",
    "backgroundColor": "#bd5e0b",
    "backgroundColorLight": "#e0b434",
    "location": "Work from home! Literally always :)",
    "description": [{
      "title": "",
      "paragraph": "We are looking for a talented back-end developer to join our development team at Spicy Sparks. You will work on what's under the hood of very big products and services that are used by millions of users every day. You will have an impact, all in a very young environment."
    }, {
      "title": "Key responsibilities:",
      "paragraph": "",
      "list": [{
        "title": "Development",
        "paragraph": "You will work on the development of our backend, making highly-scalable APIs that interact with databases. Your primary responsibilities will include designing, implementing, and testing backend solutions."
      }, {
        "title": "Engineering",
        "paragraph": "You will be responsible for defining the architecture of our backend systems, including micro-services architectures."
      }, {
        "title": "QA & Testing",
        "paragraph": "You will help shape our codebase by defining quality requirements and designing and implementing automatic tests."
      }]
    }, {
      "title": "Our ideal candidate is:",
      "paragraph": "",
      "list": [{
        "title": "Experienced",
        "paragraph": "You have experience working with C# and .NET projects, you have a knowledge about networking and Linux operating systems for servers (Debian is a plus). Furthermore, familiarity with Kubernetes, relational databases and cloud systems would be highly valued."
      }, {
        "title": "Passionate",
        "paragraph": "You are passionate about backend development and eager to stay up-to-date with the latest trends and technologies in the field."
      }, {
        "title": "Scrupulous",
        "paragraph": "You are detail-oriented and strive to write the most efficient and generic code possible."
      }, {
        "title": "Organized",
        "paragraph": "You have experience working on multi-developer projects and are proficient in using Git to keep your codebase clean and organized."
      }, {
        "title": "Humble",
        "paragraph": "You are open to feedback and always eager to collaborate and share ideas with your colleagues."
      }]
    }, {
      "title": "What we offer:",
      "paragraph": "",
      "list": [{
        "title": "Huge influence",
        "paragraph": "Your code will run on millions of devices worldwide. You will directly influence the performance of our business."
      }, {
        "title": "Young work environment",
        "paragraph": "You will work in a young, fast, and flexible environment. We have fun together while creating great products."
      }, {
        "title": "Fully remote and flexible",
        "paragraph": "You will work fully remote with good flexibility on working hours, working based on goals."
      }]
    }]
  },
  "unity3d-developer": {
    "id": "unity3d-developer",
    "title": "Unity game developer",
    "level": "Junior or Senior",
    "backgroundName": "background1.svg",
    "backgroundColor": "#01494a",
    "backgroundColorLight": "#086c5c",
    "location": "Work from home! Literally always :)",
    "description": [{
      "title": "",
      "paragraph": "We are looking for a talented Unity game developer to join our development team at Spicy Sparks. You will work on very big mobile games that are used by millions of users every day and make an impact, all in a very young environment."
    }, {
      "title": "Key responsibilities:",
      "paragraph": "",
      "list": [{
        "title": "Development",
        "paragraph": "You will work on the development of mobiles games using Unity 3D. Work closely with designers, animators, and artists to bring the game to life."
      }, {
        "title": "Engineering",
        "paragraph": "You will contribute to the development of the gameplay. Analyze and improve performance across all target platforms. Design, implement, and test game systems. Sometimes work on multiplayer features too (such as Leaderboards, Clans, Chat, Events, Tournaments, etc)"
      }, {
        "title": "QA & Testing",
        "paragraph": "You will define the quality requirements, make very optimised code, shape the codebase. You are strong with Profiling, Debugging and Testing."
      }]
    }, {
      "title": "Our ideal candidate is:",
      "paragraph": "",
      "list": [{
        "title": "Experienced",
        "paragraph": "You have already contributed to the development of different Unity games (better if for mobile). You have a good knowledge of Unity 3D and its UI, Cameras, Controls, Physics, Animations and Shaders."
      }, {
        "title": "Passionate",
        "paragraph": "You love the mobile games world. You download every game that you find just to understand how it works and its unique selling points."
      }, {
        "title": "Creative",
        "paragraph": "You are creative and details oriented, you can contribute to design and gameplay decisions."
      }, {
        "title": "Organized",
        "paragraph": "You like to keep your code neat and clean. You know how to use Git to work on a multi-developer project."
      }, {
        "title": "Humble",
        "paragraph": "You are open to receiving feedbacks about your work and you are always eager to collaborate and share ideas."
      }]
    }, {
      "title": "What we offer:",
      "paragraph": "",
      "list": [{
        "title": "Huge influence",
        "paragraph": "Your code will run on millions of devices worldwide. You will directly influence the performance of our business."
      }, {
        "title": "Young work environment",
        "paragraph": "You will work in a young, fast, and flexible environment. We have fun together while creating great products."
      }, {
        "title": "Fully remote and flexible",
        "paragraph": "You will work fully remote with good flexibility on working hours, working based on goals."
      }]
    }]
  }
}

/*
"backgroundName": "background1.svg",
"backgroundColor": "#01494a",
"backgroundColorLight": "#086c5c",*/

/*
"backgroundName": "background4.svg",
"backgroundColor": "#bd5d09",
"backgroundColorLight": "#dab632",*/
