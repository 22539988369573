import React, { FunctionComponent, memo } from "react"
import ContactUsSection from "../../aboutPage/contactUs/ContactUs"

interface Props {
}

const ContactUs: FunctionComponent<Props> = function () {
  return (
    <section id="home-contacts">
      <ContactUsSection />
    </section>
  )
}

ContactUs.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(ContactUs, arePropsEqual)
