import React, { FunctionComponent, memo, useMemo } from "react"
import spicySparksLogo from "@/assets/images/spicySparksLogo_WhiteTransparent.png"
import { Link } from "react-router-dom"
import Wave1 from "@/assets/images/waves05.png"
import Wave2 from "@/assets/images/waves06.png"
import Wave3 from "@/assets/images/waves07.png"
import "./style.scss"

const Footer: FunctionComponent = () => {

  const currentYear = useMemo((): string => new Date().getFullYear().toString(), [])

  /*<footer id="mainFooter" className="footer text-white py-5">
    <div className="container text-center">
      <p></p>
      <p>For any kind of support, contact us at <a href="hello@spicysparks.com">hello@spickysparks.com</a>, or follow us on <a href="https://www.facebook.com/spicysparks/">Facebook</a> or <a href="https://www.instagram.com/spicy_sparks/">Instagram</a>.</p>
    </div>
  </footer>*/

  return (
    <footer id="mainFooter" className="py-3 text-white position-relative">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-2 pb-4 pb-md-2 d-flex align-items-center justify-content-center justify-content-md-flex-end my-2">
            <Link to="/">
              <img src={spicySparksLogo} height={45} className="d-block me-md-5 text-primary" />
            </Link>
          </div>
          <div className="col-12 col-md-8 d-flex align-items-center justify-content-center my-2">
            <ul className="nav d-flex justify-content-center">
              <li className="nav-item">
                <Link to="/products" className="nav-link text-white">Products</Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link text-white">About</Link>
              </li>
              <li className="nav-item">
                <Link to="/tech" className="nav-link text-white">Technologies</Link>
              </li>
              <li className="nav-item">
                <Link to="/careers" className="nav-link text-white">Careers</Link>
              </li>
              <li className="nav-item">
                <Link to="/terms-and-conditions" className="nav-link text-white">Terms</Link>
              </li>
              <li className="nav-item">
                <Link to="/privacy-policy" className="nav-link text-white">Privacy</Link>
              </li>
            </ul>
          </div>
          <div className="col-12 col-md-2 d-flex align-items-center justify-content-center justify-content-md-flex-end my-2">
            <a className="text-decoration-none" href="https://www.facebook.com/spicysparks/">
              <i className="d-block fa fa-facebook-official fa-lg ms-3 mx-2"></i>
            </a>
            <a className="text-decoration-none" href="https://www.instagram.com/spicy_sparks/">
              <i className="d-block fa fa-instagram fa-lg ms-3 mx-2"></i>
            </a>
            <a className="text-decoration-none" href="https://twitter.com/spicy_sparks">
              <i className="d-block fa fa-twitter fa-lg ms-3 mx-2"></i>
            </a>
            <a className="text-decoration-none" href="https://www.linkedin.com/company/spicy-sparks/">
              <i className="d-block fa fa-linkedin fa-lg ms-3 mx-2"></i>
            </a>
          </div>
          <div className="col-12 col-md-8 mx-auto pt-5 pb-2 px-4 text-center">
            <small className="my-5">Via Atene 3, 41049, Sassuolo (MO), Italy - VAT 03905910364, REA number MO - 427368, contributed capital €10,000.00<br />Spicy Sparks S.R.L. © 2020-{currentYear}</small>
          </div>
        </div>
      </div>
      <div className="waves">
        <img src={Wave1} className="position-absolute wave1" />
        <img src={Wave2} className="position-absolute wave2" />
        <img src={Wave3} className="position-absolute wave3" />
      </div>
    </footer>
  )
}

Footer.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Footer, arePropsEqual)
