import React, { FunctionComponent, memo } from "react"
import Hero from "./hero/Hero"
import ContactUs from "./contactUs/ContactUs"
import "./style.scss"

interface Props {

}

const AboutPage: FunctionComponent<Props> = function () {

  return (
    <div id="aboutPage">
      <Hero />
      <ContactUs />
    </div>
  )
}

AboutPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(AboutPage, arePropsEqual)
