import React, { FunctionComponent, memo } from "react"
import Apps from "./apps/Apps"
import Geousers from "./geousers/Geousers"
import Reviews from "./reviews/Reviews"
import ContactUs from "./contactUs/ContactUs"
import Careers from "./careers/Careers"
import Counter from "./counter/Counter"
import "./style.scss"

const HomePage: FunctionComponent = function () {
  return (
    <div id="homePage">
      <Apps />
      <Counter />
      <Geousers />
      <Careers />
      <Reviews />
      <ContactUs />
    </div>
  )
}

HomePage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(HomePage, arePropsEqual)
