import React, { FunctionComponent, memo } from "react"
import Hero from "./hero/Hero"
import Intro from "./intro/Intro"
import Values from "./values2/Values"
import Positions from "./positions/Positions"
import "./style.scss"

const CareersPage: FunctionComponent = function () {
  return (
    <div id="careersPage">
      <Hero />
      <Intro />
      <Values />
      <Positions />
    </div>
  )
}

CareersPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(CareersPage, arePropsEqual)
