import React, { FunctionComponent, memo } from "react"
import Fade from "react-reveal/Fade"
import Pattern from "@/assets/images/pattern01.svg"
import Illustration1 from "@/assets/images/notFound01.svg"
import Illustration2 from "@/assets/images/notFound02.svg"
import { Feather } from "react-web-vector-icons"
import { Link } from "react-router-dom"
import "./style.scss"

const Hero: FunctionComponent = function () {
  return (
    <section id="notfound-hero" className="pb-5 bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container h-100 text-center py-5 d-flex align-items-center justify-content-center flex-column">
        <Fade bottom cascade delay={200}>
          <ul className="illustrations d-flex flex-row stars justify-content-center align-items-center p-0">
            <li className="d-block mx-2 mx-md-3">
              <img className="flex-shrink-0 w-100 d-block illustration1" src={Illustration1} />
            </li>
            <li className="d-block mx-2 mx-md-3">
              <img className="flex-shrink-0 w-100 d-block illustration2" src={Illustration2} />
            </li>
            <li className="d-block mx-2 mx-md-3">
              <img className="flex-shrink-0 w-100 d-block illustration3" src={Illustration1} />
            </li>
          </ul>
        </Fade>
        <Fade bottom delay={500}>
          <h1 className="fw-bold display-4 mt-4">Page not found</h1>
          <h6 className="fw-bold lead mt-3 text-secondary">Sorry, we can't find the page you're looking for.</h6>
          <Link to="/" className="btn btn-primary bg-multicolor border-0 text-white ps-3 pe-4 py-2 mt-5 rounded-pill fw-bold">
            <div className="d-flex align-items-center">
              <Feather
                name="home"
                color="white"
                size={22}
              />
              <span className="ps-3">
                Back to home
              </span>
            </div>
          </Link>
        </Fade>
      </div>
    </section>
  )
}

Hero.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Hero, arePropsEqual)
