import React, { FunctionComponent, memo } from "react"
import ESound from "./ESound/ESound"
import Tonify from "./Tonify/Tonify"
import More from "./more/More"
import Hero from "./hero/Hero"
import "./style.scss"

interface Props {

}

const WorksPage: FunctionComponent<Props> = function () {

  return (
    <div id="worksPage">
      <Hero />
      <ESound />
      {/* <Tonify /> */}
      {/* <More /> */}
    </div>
  )
}

WorksPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(WorksPage, arePropsEqual)
