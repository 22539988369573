import React, { FunctionComponent, memo, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import Background from "@/assets/images/bg-waves02.svg"
import Wave from "@/assets/images/waves09.png"
import Tree1 from "@/assets/images/tree01.svg"
import Tree2 from "@/assets/images/tree02.svg"
import Tree3 from "@/assets/images/tree03.svg"
import Home from "@/assets/images/home.svg"
import Fade from "react-reveal/Fade"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import Lottie from 'react-lottie';
import scrollAnimation from "@/assets/animation/scroll.json"
import "./style.scss"

const scrollAnimationData = {
  loop: true,
  autoplay: true,
  animationData: scrollAnimation
}

interface Props {}

const Intro: FunctionComponent<Props> = function (props) {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="careers-intro" className="py-5 d-flex align-items-center position-relative">
      <div className="position-absolute top-0 start-0 end-0 bottom-0 background" style={{
        backgroundImage: `url('${Background}')`
      }} />
      <div className="container position-relative">
        <div className="row">
          <VisibilitySensor partialVisibility onChange={visibilityChanged}>
            <div className="col-md-6 mx-auto d-flex flex-column align-items-center justify-content-center text-center text-white pb-5">
              <h1 className="fw-bold display-5 mb-2">Why we’re an incredible<br />place to work</h1>
              <h6 className="lead mt-5">Working at Spicy Sparks for us is such a fun and rewarding part of our life. It’s nothing like the average professional experience.<br /><br /></h6>
              <h6 className="lead d-flex align-items-center justify-content-center opacity-75">
                <span className="me-3">Here’s what makes it so special</span>
                <Lottie
                  options={scrollAnimationData}
                  height={40}
                  width={20}
                />
              </h6>
            </div>
          </VisibilitySensor>
        </div>
      </div>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree1" src={Tree1} />
      </Fade>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree2" src={Tree2} />
        <img className="position-absolute home" src={Home} />
      </Fade>
      <Fade bottom when={isVisible}>
        <img className="position-absolute tree3" src={Tree3} />
      </Fade>
      <img className="wave-after w-100" src={Wave} />
    </section>
  )
}

Intro.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Intro, arePropsEqual)
