import React, { FunctionComponent, Component, ReactNode } from "react"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import ReactGA from "react-ga"
import { ThemeProvider } from "@/helpers/theme/theme"
import ScrollRestore from "./ScrollRestore"
import { ParallaxProvider } from "react-scroll-parallax"
import { createBrowserHistory } from "history"
import "./style.scss"

import NavBar from "../navbar/NavBar"
import Footer from "../footer/Footer"

import HomePage from "../homePage/HomePage"
import TechPage from "../techPage/TechPage"
import CareersPage from "../careersPage/CareersPage"
import CareerPage from "../careerPage/CareerPage"
import AboutPage from "../aboutPage/AboutPage"
import WorksPage from "../worksPage/WorksPage"
import PrivacyPage from "../privacyPage/PrivacyPage"
import NotFoundPage from "../notFoundPage/NotFoundPage"
import TermsPage from "../termsPage/TermsPage"

const history = createBrowserHistory()

history.listen(listener => {
  ReactGA.set({ page: listener.location.pathname })
  ReactGA.pageview(listener.location.pathname)
})

ReactGA.set({ page: history.location.pathname })
ReactGA.pageview(history.location.pathname)

type Props = {}

export default class RouterScreen extends Component<Props> {
  render (): ReactNode {
    return (
      <BrowserRouter>
        <ScrollRestore />
        <ThemeProvider>
          <Main />
        </ThemeProvider>
      </BrowserRouter>
    )
  }
}

const MainComponent: FunctionComponent = () => (
  <>
  <NavBar />
  <ParallaxProvider>
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/products" element={<WorksPage />} />
      <Route path="/tech" element={<TechPage />} />
      <Route path="/careers" element={<CareersPage />} />
      <Route path="/careers/:id" element={<CareerPage />} />
      <Route path="/privacy" element={<Navigate to='/privacy-policy' />} />
      <Route path="/privacy-policy" element={<PrivacyPage />} />
      <Route path="/terms" element={<Navigate to='/terms-and-conditions' />} />
      <Route path="/terms-and-conditions" element={<TermsPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  </ParallaxProvider>
  <Footer />
  {/*<SplashScreen />*/}
  </>
)

const Main = MainComponent

/*const PrivateRoute: React.SFC<any> = (props) => {

  if(!store.getState().user.get("id"))
    return <Navigate to={{
      pathname: "/login",
      state: { from: props.location }
    }} />

  return <Route {...props} />
}*/
