import pandoraTheme from "./themes/pandora"
import { ThemeInterface } from "./themes/interface"
import { createTheming } from "@callstack/react-theme-provider"

const defaultThemeId: string = "pandora"
//let firstChangeThemeEventFired: boolean = false
let systemTheme: string = "pandora"

export const defaultTheme: ThemeInterface = pandoraTheme

export const { ThemeProvider, withTheme, useTheme } = createTheming(defaultTheme)

export const getSystemTheme = function (): string {
  return defaultThemeId
}

export const getThemeIdFromSystemTheme = function (): string {
  switch (systemTheme) {
    default:
      return defaultThemeId
  }
}

export const getThemeFromId = function (themeId: string): ThemeInterface {
  switch (themeId) {
    case "pandora":
      return pandoraTheme
    default:
      return defaultTheme
  }
}
