import React, { FunctionComponent, memo, useEffect, useState } from "react"
import DOC from './TERMS_AND_CONDITIONS.md'
import ReactMarkdown from 'react-markdown'
import "./style.scss"

const TermsPage: FunctionComponent = function () {

  let [ content, setContent ] = useState("")

  useEffect(()=> {
    fetch(DOC.toString())
    .then((res) => res.text())
    .then((md) => setContent(md))
  }, [])

  return (
    <div id="termsPage" className="bg-white">
      <div className="container position-relative py-5 mt-5">
        <h1 className="fw-bold display-5 mt-5">Terms and Conditions</h1>
        <ReactMarkdown children={content} className="py-5" />
      </div>
    </div>
  )
}

TermsPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(TermsPage, arePropsEqual)
