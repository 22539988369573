import React, { FunctionComponent, useEffect } from "react"
import { useLocation } from "react-router-dom"

const ScrollToTop: FunctionComponent = () => {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // @ts-ignore
      behavior: "instant"
    })
  }, [ location.pathname ])

  return null
}

export default ScrollToTop
