import React, { FunctionComponent, memo } from "react"
import CareersSection from "../../careersPage/careers/Careers"

interface Props {
}

const Careers: FunctionComponent<Props> = function () {
  return (
    <section id="home-careers">
      <CareersSection />
    </section>
  )
}

Careers.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Careers, arePropsEqual)
