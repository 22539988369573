import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from "react"
import Pattern from "@/assets/images/pattern01.svg"
import Wave1 from "@/assets/images/waves14.png"
import Wave2 from "@/assets/images/waves15.png"
import Wave3 from "@/assets/images/waves16.png"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import Fade from "react-reveal/Fade"
import Slide from "react-reveal/Slide"
import { exaClient } from "@/helpers/requests/exaClient"
import { bigNumberFormatter, numberWithCommas } from "@/helpers/formatters/bigNumbersFormatter"
import { emitEvent, onEvent, removeEvent } from "@/helpers/events/events"
import "./style.scss"

const Counter: FunctionComponent = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const [ usersAmount, setUsersAmount ] = useState(16165180)

  const [ counterAnimation, setCounterAnimation ] = useState(false)

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  const animateCounterLabel = useCallback(() => {
    setCounterAnimation(true)
    setTimeout(() => setCounterAnimation(false), 1700)
  }, [])

  useEffect(() => {
    const animationListener = onEvent("animateCounterLabel", animateCounterLabel)
    return () => {
      removeEvent(animationListener as string)
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timer | undefined
    (async () => {
      try {
        const res = await exaClient.get("/public/livedata")
        if(!res?.data?.totalSignups)
          return
        setUsersAmount(res.data.totalSignups)
        const dailyIncrement = res.data.dailyIncrement
        timeoutId = setInterval(() => {
          setUsersAmount((amount) => {
            const newAmount = amount + (dailyIncrement / (24 * 60 * 30))
            if(Math.round(newAmount) != Math.round(amount))
              emitEvent("animateCounterLabel")
            if(newAmount != amount)
              return newAmount
            return amount
          })
        }, 2000)
      }
      catch (err) {}
    })()
    return () => {
      timeoutId && clearInterval(timeoutId)
    }
  }, [])

  return (
    <section id="home-counter" className="position-relative py-5">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="position-absolute waves">
        <img src={Wave1} className="position-absolute wave1" />
        <img src={Wave2} className="position-absolute wave2" />
        <img src={Wave3} className="position-absolute wave3" />
      </div>
      <div className="container position-relative text-center h-100 d-flex flex-column align-items-center justify-content-center">
        <div className="row h-100">
          <div className="col-12 col-md-6 pe-auto pe-md-5 text-start d-flex align-items-center align-items-md-start flex-column justify-content-center">
            <VisibilitySensor partialVisibility onChange={visibilityChanged}>
              <Fade bottom when={isVisible && (usersAmount > 0)}>
                <h1 className="fw-bold display-5 text-center my-0 text-md-start">Doing good for</h1>
                <div>
                  <h1 className={`d-block display-4 fw-bold my-2 text-multicolor ${counterAnimation ? " animate__animated animate__tada" : ""}`}>{numberWithCommas(Math.round(usersAmount))}</h1>
                </div>
                <h1 className="fw-bold display-5 text-center my-0 text-md-start">Beautiful users.</h1>

                <h6 className="lead mt-5 pe-auto pe-md-2 pe-lg-auto">
                  Our apps are downloaded tens of millions of times and daily accesses are hundreds of thousands.
                  <br />We are convinced that this success is given by the care and quality we put into our products.
                </h6>
              </Fade>
            </VisibilitySensor>
          </div>
          <div className="col-12 col-md-6 mt-5 mt-md-0 chart d-flex justify-content-center align-items-end pb-5 position-relative">
            <p className="position-absolute legend start-0 mx-3 fw-bold lead text-start">Our growth over the years</p>
            <div className="bar h-25 mx-1 mx-xl-3 w-100 position-relative">
              <div className="w-100 h-100 wrapper position-relative overflow-hidden">
                <Slide bottom when={isVisible} delay={100}>
                  <div className="w-100 h-100 bar-column position-relative">
                    <h3 className="position-absolute text-uppercase fw-bold text-white text-center top-0 start-0 end-0 mt-3">{bigNumberFormatter(usersAmount * 0.14)}</h3>
                  </div>
                </Slide>
              </div>
              <h3 className="position-absolute text-uppercase date fw-bold text-black text-center start-0 end-0 mt-3">{currentYear - 3}</h3>
            </div>
            <div className="bar h-50 mx-1 mx-xl-3 w-100 position-relative">
              <div className="w-100 h-100 wrapper position-relative overflow-hidden">
                <Slide bottom when={isVisible} delay={200}>
                  <div className="w-100 h-100 bar-column position-relative">
                    <h3 className="position-absolute text-uppercase fw-bold text-white text-center top-0 start-0 end-0 mt-3">{bigNumberFormatter(usersAmount * 0.26)}</h3>
                  </div>
                </Slide>
              </div>
              <h3 className="position-absolute text-uppercase date fw-bold text-black text-center start-0 end-0 mt-3">{currentYear - 2}</h3>
            </div>
            <div className="bar h-75 mx-1 mx-xl-3 w-100 position-relative">
              <div className="w-100 h-100 wrapper position-relative overflow-hidden">
                <Slide bottom when={isVisible} delay={300}>
                  <div className="w-100 h-100 bar-column position-relative">
                    <h3 className="position-absolute text-uppercase fw-bold text-white text-center top-0 start-0 end-0 mt-3">{bigNumberFormatter(usersAmount * 0.54)}</h3>
                  </div>
                </Slide>
              </div>
              <h3 className="position-absolute text-uppercase date fw-bold text-black text-center start-0 end-0 mt-3">{currentYear - 1}</h3>
            </div>
            <div className="bar h-100 mx-1 mx-xl-3 w-100 position-relative">
              <div className="w-100 h-100 wrapper position-relative overflow-hidden">
                <Slide bottom when={isVisible} delay={400}>
                  <div className="w-100 h-100 bar-column position-relative bg-multicolor2">
                    <h3 className="position-absolute text-uppercase fw-bold text-white text-center top-0 start-0 end-0 mt-3">{bigNumberFormatter(usersAmount)}</h3>
                  </div>
                </Slide>
              </div>
              <h3 className="position-absolute text-uppercase date fw-bold text-multicolor text-center start-0 end-0 mt-3">{currentYear}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

Counter.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Counter, arePropsEqual)
