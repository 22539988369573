import React, { FunctionComponent, memo, useCallback, useState } from "react"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import Fade from "react-reveal/Fade"
import Query from "@/assets/images/code.svg"
import Chart from "@/assets/images/chart.svg"
import Wave from "@/assets/images/waves04.png"
import "./style.scss"

const Analytics: FunctionComponent = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="tech-analytics" className="pt-5 d-flex align-items-center bg-white">
      <img className="wave-before w-100" src={Wave} />
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container position-relative">
          <div className="row">
            <div className="ms-auto col-12 col-md-5 me-0 me-md-auto d-flex flex-column align-items-start justify-content-center">
              <h1 className="display-5 fw-bold mb-4">Exa Analytics</h1>
              <p>At Spicy Sparks we tend to adopt a data-driven approach to decisions as often as possible.
              This requires the collection of a series of data that provides us with information on the behaviour of users during the use of our apps, from when (and how) they first arrive, to the way in which they interact with the app every day.
              Furthermore, this information, reworked by our apps, offers the user a much more personalized service, which adapts to his preferences: this is a key feature that we always implement.
              So we created Exa Analytics.</p>
            </div>
            <div className="col-12 col-md-4 me-auto">
              <Fade bottom when={isVisible}>
                <img className="img-fluid mt-3 mt-md-5" src={Query} />
              </Fade>
            </div>
            <div className="col-12 col-md-10 mx-auto mt-4 mt-md-5">
              <p>Our data collection and processing system is completely customized and allows us to go further than the already existing services.
              We use Apache Kafka to orchestrate the thousands of events per second we receive, which are transformed and roughed out with extreme speed.
              The data is archived using ClickHouse, an extremely powerful database that allows a huge amount of events to be recorded without bottlenecks.
              The analysis of this data, which we can do through SQL queries, even very sophisticated ones, has opened our eyes to many patterns that otherwise would have been impossible to see and make better quality decisions.
              This system still helps generate recommended content for users in our apps, guaranteeing a personalized experience that keeps our community loyal from day one.</p>
            </div>
            <div className="col-12 col-md-10 mx-auto mt-4">
              <Fade bottom when={isVisible}>
                <img className="img-fluid" src={Chart} />
              </Fade>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  )
}

Analytics.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Analytics, arePropsEqual)
