import React, { FunctionComponent, memo, useCallback, useState } from "react"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import Wave from "@/assets/images/waves04.png"
import Fade from "react-reveal/Fade"
import Ratio from "react-ratio"
import DynamicDevice1 from "@/assets/images/dynamicDevice01.png"
import DynamicDevice2 from "@/assets/images/dynamicDevice02.png"
import DynamicDevice3 from "@/assets/images/dynamicDevice03.png"
import DynamicDevice4 from "@/assets/images/dynamicDevice04.png"
import DynamicDevice5 from "@/assets/images/dynamicDevice05.png"
import DynamicDevice6 from "@/assets/images/dynamicDevice06.png"
import DynamicDevice7 from "@/assets/images/dynamicDevice07.png"
import DynamicDevice8 from "@/assets/images/dynamicDevice08.png"
import DynamicDevice9 from "@/assets/images/dynamicDevice09.png"
import DynamicDevice10 from "@/assets/images/dynamicDevice10.png"
import DynamicDevice11 from "@/assets/images/dynamicDevice11.png"
import DynamicDevice12 from "@/assets/images/dynamicDevice12.png"
import DynamicDevice13 from "@/assets/images/dynamicDevice13.png"
import { Parallax, useParallaxController } from "react-scroll-parallax"
import "./style.scss"

const Apps: FunctionComponent = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  return (
    <section id="tech-apps" className="py-5 d-flex align-items-center bg-white">
      <img className="wave-before w-100" src={Wave} />
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container position-relative h-100">
          <div className="row h-100">
            <div className="col-12 col-md-6 pb-5 h-100 d-flex flex-column align-items-start justify-content-center">
              <Fade bottom when={isVisible}>
                <h1 className="display-5 fw-bold mb-4">Cutting-edge mobile tech</h1>
                <p>Our apps showcase clean, responsive, and complex animation UIs. On top of that, they are also accessible, integrated with dozens of third-party software or OS features and handle very complex business logic, to lighten the servers.
                To allow this, we have adopted a modern technology, organized in a very modular way, using cores and reusable components.
                Integration with custom libraries makes everything even more powerful.<br /><br />Our apps are based on React and React Native, organized with NPM and GIT and using the latest technologies such as Typescript and Axios.
                The UIs aspire to maximum fluidity and use libraries such as Reanimated and Gesture Handler, for React Native. To manage the business logic, we use high-performance technologies such as Redux and Immutable.js.
                In this way, our projects are highly scalable and optimized, as well as being very versatile to support more and more platforms easily.</p>
              </Fade>
            </div>
            <div className="col-12 col-md-6 col-lg-6 mx-auto position-relative col-12 col-md-5 col-lg-4 d-flex align-items-center justify-content-center">
              <Ratio ratio={1.2} className="dynamicDevice w-100 position-relative">
                <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice4} onLoad={updateParallax} />
                <Parallax
                  className="position-absolute start-0 top-0 w-100 h-100"
                  translateY={[ 0, -10 ]}
                >
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice11} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice6} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice3} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice12} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice13} onLoad={updateParallax} />
                </Parallax>
                <Parallax
                  className="position-absolute start-0 top-0 w-100 h-100"
                  translateY={[ 0, -20 ]}
                >
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice10} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice7} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice2} onLoad={updateParallax} />
                </Parallax>
                <Parallax
                  className="position-absolute start-0 top-0 w-100 h-100"
                  translateY={[ 0, -30 ]}
                >
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice9} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice8} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice1} onLoad={updateParallax} />
                  <img className="position-absolute start-0 top-0 w-100 h-100" src={DynamicDevice5} onLoad={updateParallax} />
                </Parallax>
              </Ratio>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  )
}

Apps.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Apps, arePropsEqual)
