import React from "react"
import { createRoot } from 'react-dom/client'
import Router from "./router/Router"
import whyDidYouRender from "@welldone-software/why-did-you-render"
import ReactGA from "react-ga"
import "animate.css";
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap/dist/js/bootstrap.min.js"
import "./styles/global.scss"
import '@/assets/fonts/Satoshi-Bold.ttf'
import '@/assets/fonts/Satoshi-BoldItalic.ttf'
import '@/assets/fonts/Satoshi-Black.ttf'
import '@/assets/fonts/Satoshi-BlackItalic.ttf'
import '@/assets/fonts/Satoshi-Light.ttf'
import '@/assets/fonts/Satoshi-LightItalic.ttf'
import '@/assets/fonts/Satoshi-Italic.ttf'
import '@/assets/fonts/Satoshi-Medium.ttf'
import '@/assets/fonts/Satoshi-MediumItalic.ttf'
import '@/assets/fonts/Satoshi-Regular.ttf'
import '@/assets/fonts/Satoshi-Variable.ttf'
import '@/assets/fonts/Satoshi-VariableItalic.ttf'
import { googleAnalyticsId } from "@/helpers/constants/constants"

ReactGA.initialize(googleAnalyticsId)

if (process?.env && process?.env?.NODE_ENV !== "production")
  whyDidYouRender(React)

const root = createRoot(document.getElementById('root')!)

root.render(<Router />)
