import React, { FunctionComponent, memo, useCallback, useEffect } from "react"
import Fade from "react-reveal/Fade"
import { Parallax, useParallaxController } from "react-scroll-parallax"
import Pattern from "@/assets/images/pattern01.svg"
import Device1 from "@/assets/images/device01.png"
import Device2 from "@/assets/images/device02.png"
import Device3 from "@/assets/images/device03.png"
import Wave1 from "@/assets/images/waves01.png"
import Wave2 from "@/assets/images/waves02.png"
import Wave3 from "@/assets/images/waves03.png"
import { Entypo } from "react-web-vector-icons"
import { HashLink } from "react-router-hash-link"
import "./style.scss"

const Hero: FunctionComponent = function () {

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  useEffect(() => {
    updateParallax()
    window.addEventListener("load", updateParallax)
    return () => {
      window.removeEventListener("load", updateParallax)
    }
  }, [])

  return (
    <section id="works-hero" className="bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container position-relative text-center h-100 d-flex flex-column align-items-center justify-content-center">
        <Fade bottom>
          <h1 className="fw-bold mt-4">Making the next<br />level apps.</h1>
          <HashLink to="/products#works-esound" className="btn btn-primary bg-multicolor border-0 text-white ps-3 pe-4 py-2 mt-5 rounded-pill fw-bold">
            <div className="d-flex align-items-center">
              <Entypo
                name="chevron-down"
                color="white"
                size={25}
              />
              <span className="ps-3">
                Explore them
              </span>
            </div>
          </HashLink>
        </Fade>
        <Parallax className="overlay position-absolute w-100 h-100" translateY={[ 60, 0 ]}>
          <div className="position-absolute device1">
            <Fade delay={200} duration={800} bottom>
              <img src={Device1} />
            </Fade>
          </div>
          <div className="position-absolute device2">
            <Fade delay={200} duration={1000} bottom>
              <img src={Device2} />
            </Fade>
          </div>
          <div className="position-absolute device3">
            <Fade delay={200} duration={900} bottom>
              <img src={Device3} />
            </Fade>
          </div>
        </Parallax>
      </div>
      <img src={Wave1} className="position-absolute wave1" onLoad={updateParallax} />
      <img src={Wave2} className="position-absolute wave2" onLoad={updateParallax} />
      <img src={Wave3} className="position-absolute wave3" onLoad={updateParallax} />
    </section>
  )
}

Hero.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Hero, arePropsEqual)
