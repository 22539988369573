import React, { FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from "react"
import Fade from "react-reveal/Fade"
import IcebergIllustration from "@/assets/images/bg-iceberg.svg"
import IcebergTopIllustration from "@/assets/images/bg-iceberg-top.svg"
import IcebergBottomIllustration from "@/assets/images/bg-iceberg-bottom.svg"
import WavesIllustration from "@/assets/images/bg-iceberg-wave.svg"
import Animal1Illustration from "@/assets/images/bg-iceberg-animal1.svg"
import Animal2Illustration from "@/assets/images/bg-iceberg-animal2.svg"
import Animal3Illustration from "@/assets/images/bg-iceberg-animal3.svg"
import Animal4Illustration from "@/assets/images/bg-iceberg-animal4.svg"
import Animal5Illustration from "@/assets/images/bg-iceberg-animal5.svg"
import Animal6Illustration from "@/assets/images/bg-iceberg-animal6.svg"
import Animal7Illustration from "@/assets/images/bg-iceberg-animal7.svg"
import Animal8Illustration from "@/assets/images/bg-iceberg-animal8.svg"
import Animal9Illustration from "@/assets/images/bg-iceberg-animal9.svg"
import Animal10Illustration from "@/assets/images/bg-iceberg-animal10.svg"
import Animal11Illustration from "@/assets/images/bg-iceberg-animal11.svg"
import Wave1 from "@/assets/images/waves11.png"
import Wave2 from "@/assets/images/waves12.png"
import Wave3 from "@/assets/images/waves13.png"
import "./style.scss"

interface Props {
}

interface Section {
  title?: string,
  desc?: string,
  top?: number,
  left?: number,
  right?: number
}

const Iceberg: FunctionComponent<Props> = function () {

  const [ isMobile, setIsMobile ] = useState(window.innerWidth < 768)

  const handleResize = useCallback(() => {
    setIsMobile(window.innerWidth < 768)
  }, [])

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const sections = useMemo<Array<Section>>(() => ([{
    title: "Our own Apps",
    left: 21,
    right: 65,
  }, {
    title: "A sophisticated client tech",
    desc: "Our apps must support very complex logic, local databases and integrate dozens of third-party platforms. To win all these challenges, we have built very precise standards and technologies, which allow great flexibility and convenience in development.",
    top: 5,
    left: 14,
    right: 45
  }, {
    title: "Great UI tools",
    desc: "Our apps seek a clean, accessible UI, with cool and complex animations but also responsiveness for hundreds of devices. Building organized UIs with custom libraries and performing technologies is more than necessary.",
    left: 18,
    right: 38,
  }, {
    title: "Cutting-edge servers",
    desc: "Our services are used by millions of users every day. To support dozens of thousands of requests each second, we need to build an extremely performant server architecture. We organized our back-end in microservices, with an impressive cure for the details.",
    left: 30,
    right: 15
  }, {
    title: "Custom data analytics tools",
    desc: "Our apps generate massive amounts of data, used to offer a high-quality service and make strategic decisions. To dig with fast queries inside this world of information, we created our own technologies to collect, organize and analyze petabytes of data.",
    left: 27,
    right: 25
  }, {
    title: "Advanced databases",
    desc: "Our apps are cross-platform and users can log in, share and interact with the app content. To allow this and many more features, with an extreme traffic of information involved, we have to adopt very optimised and advanced database strategies.",
    left: 35,
    right: 28
  }]), [])

  return (
    <section id="tech-iceberg" className="position-relative pt-5">
      <div className="position-absolute waves">
        <img src={Wave1} className="position-absolute wave1" />
        <img src={Wave2} className="position-absolute wave2" />
        <img src={Wave3} className="position-absolute wave3" />
      </div>
      <div className="w-100 h-100 overflow-hidden wrapper">
        <div className="container position-relative">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 col-xl-12 mx-auto pt-md-5 px-0 px-md-auto">
              <h1 className="d-block d-md-none text-center mb-5 fw-bold display-5 text-white">Find out what lies<br />behind what we do.</h1>
              { isMobile ? <div className="w-100 iceberg position-relative">
                <ul className="list-group list-group-flush">
                  { sections.map((section, i) => (
                    <li
                      key={i}
                      className={`list-group-item text-white border-0 m-0 text-center d-flex align-items-center flex-column justify-content-center${((i === 0) || (i === sections.length - 1)) ? "" : " py-3"}${(i === 0) ? " position-relative" : ""}`}
                      style={{
                        minHeight: 200,
                        ...((i === 0) && {
                          paddingTop: 30,
                          paddingRight: 80
                        }),
                        ...((i === sections.length - 1) && {
                          paddingBottom: 90,
                          paddingLeft: 80,
                          paddingRight: 80
                        })
                      }}
                    >
                      <h4 className="title fw-bold">{section.title}</h4>
                      <p className="desc mb-0"><span className="opacity-75">{section.desc}</span></p>
                    </li>
                  ))}
                </ul>
                <img src={IcebergTopIllustration} className="position-absolute iceberg-top start-0 end-0 w-100" />
                <img src={IcebergBottomIllustration} className="position-absolute iceberg-bottom start-0 end-0 w-100" />
                <img src={WavesIllustration} className="iceberg-waves position-absolute start-0 w-100 d-block" />
                <Fade bottom>
                  <img src={Animal1Illustration} className="animal1 position-absolute d-block" />
                  <img src={Animal2Illustration} className="animal2 position-absolute d-block" />
                  <img src={Animal3Illustration} className="animal3 position-absolute d-none d-md-block" />
                  <img src={Animal4Illustration} className="animal4 position-absolute d-none d-md-block" />
                  <img src={Animal5Illustration} className="animal5 position-absolute d-block" />
                  <img src={Animal6Illustration} className="animal6 position-absolute d-block" />
                  <img src={Animal7Illustration} className="animal7 position-absolute d-block" />
                  <img src={Animal8Illustration} className="animal8 position-absolute d-block" />
                  <img src={Animal9Illustration} className="animal9 position-absolute d-block" />
                  <img src={Animal10Illustration} className="animal10 position-absolute d-block" />
                  <img src={Animal11Illustration} className="animal11 position-absolute d-block" />
                </Fade>
              </div> : <div className="w-100 iceberg position-relative mx-auto">
                <ul className="position-absolute top-0 start-0 end-0 bottom-0 list-group list-group-flush">
                  { sections.map((section, i) => (
                    <li
                      key={i}
                      className={`list-group-item text-white border-0 m-0 d-flex align-items-start justify-content-center flex-column${!section.desc ? " text-center" : ""}`}
                      style={{
                        paddingTop: `${section.top || 0}%`,
                        paddingLeft: `${section.left || 0}%`,
                        paddingRight: `${section.right || 0}%`,
                        height: `${1 / sections.length * 100}%`
                      }}
                    >
                      <h3 className={`title fw-bold${section.desc ? " withContent" : ""}`}>{section.title}</h3>
                      <p className="desc"><span className="opacity-75">{section.desc}</span></p>
                    </li>
                  ))}
                </ul>
                <img src={IcebergIllustration} className="position-relative d-block w-100" />
                <img src={WavesIllustration} className="iceberg-waves position-absolute d-block" />
                <Fade bottom>
                  <img src={Animal1Illustration} className="animal1 position-absolute d-block" />
                  <img src={Animal2Illustration} className="animal2 position-absolute d-block" />
                  <img src={Animal3Illustration} className="animal3 position-absolute d-block" />
                  <img src={Animal4Illustration} className="animal4 position-absolute d-block" />
                  <img src={Animal5Illustration} className="animal5 position-absolute d-block" />
                  <img src={Animal6Illustration} className="animal6 position-absolute d-block" />
                  <img src={Animal7Illustration} className="animal7 position-absolute d-block" />
                  <img src={Animal8Illustration} className="animal8 position-absolute d-block" />
                  <img src={Animal9Illustration} className="animal9 position-absolute d-block" />
                  <img src={Animal10Illustration} className="animal10 position-absolute d-block" />
                  <img src={Animal11Illustration} className="animal11 position-absolute d-block" />
                </Fade>
              </div> }
            </div>
          </div>
          <h1 className="d-none d-md-block top-0 end-0 text-end mt-5 fw-bold display-5 w-50 position-absolute text-white">Find out what lies<br />behind what we do.</h1>
        </div>
      </div>
    </section>
  )
}

Iceberg.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Iceberg, arePropsEqual)
