import React, { FormEventHandler, FunctionComponent, memo, useCallback, useState } from "react"
import Fade from "react-reveal/Fade"
import Background from "@/assets/images/bg-waves01.svg"
import Pattern from "@/assets/images/pattern04.svg"
import "./style.scss"
import { apiClient } from "@/helpers/requests/apiClient"

type SendStatus = "none" | "sending" | "sent"

const ContactUs: FunctionComponent = function () {

  const [ validated, setValidated ] = useState<boolean>(false)
  const [ invalids, setInvalids ] = useState<{ [id: string ]: boolean }>({})
  const [ email, setEmail ] = useState<string>("")
  const [ message, setMessage ] = useState<string>("")
  const [ status, setStatus ] = useState<SendStatus>("none")

  const handleEmail = useCallback((event) => {
    setInvalids(({ email, ...rest }) => rest)
    setEmail(event.target.value)
  }, [])

  const handleMessage = useCallback((event) => {
    setInvalids(({ message, ...rest }) => rest)
    setMessage(event.target.value)
  }, [])

  const sendMessage = ((event) => {
    event.preventDefault()
    if(status !== "none")
      return
    setValidated(true)
    if(!email || !message) {
      setInvalids({
        email: !email,
        message: !message
      })
      return
    }
    setInvalids({})
    setStatus("sending")
    setTimeout(async () => {
      try {
        const reqParams = new URLSearchParams()
        reqParams.append("FromEmail", email)
        reqParams.append("Message", message)
        await apiClient.post("/api/support/newissue", reqParams)
        setStatus("sent")
        setEmail("")
        setMessage("")
        setInvalids({})
        setTimeout(() => setStatus("none"), 4000)
      }
      catch (err) {
        setStatus("none")
      }
    }, 200)
  }) as FormEventHandler<HTMLFormElement>

  return (
    <section id="contactUs" className="py-5">
      <div className="position-absolute top-0 start-0 end-0 bottom-0 background" style={{
        backgroundImage: `url('${Background}')`
      }} />
      <div className="position-absolute top-0 start-0 bottom-0 end-0 pattern" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container h-100 position-relative">
        <div className="row h-100">
          <div className="col-md-6 mx-auto pt-5 h-100 d-flex flex-column align-items-center justify-content-center text-center">
            <Fade bottom>
              <h1 className="fw-bold display-5 mb-2">We'd love to<br />hear from you.</h1>
            </Fade>
            <Fade bottom>
              <form
                className={`py-5 w-100 needs-validation${validated ? " was-validated" : ""}`}
                onSubmit={sendMessage}
                noValidate
              >
                <div className="form-row">
                  <div className="form-group w-100">
                    <input
                      type="email"
                      value={email}
                      onChange={handleEmail}
                      className="form-control form-control-lg"
                      placeholder="Email"
                    />
                    { invalids.email && <div className="invalid-feedback text-black d-block opacity-75 text-start ps-2">
                      Please insert your email
                    </div> }
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="form-group w-100">
                    <textarea
                      value={message}
                      onChange={handleMessage}
                      className="form-control form-control-lg"
                      rows={3}
                      placeholder="Your message"
                    ></textarea>
                    { invalids.message && <div className="invalid-feedback text-black d-block opacity-75 text-start ps-2">
                      Write us something cool!
                    </div> }
                  </div>
                </div>
                <div className="form-row my-3">
                  <div className="form-group w-100">
                    <button type="submit" className="btn btn-lg btn-autumn mt-3 px-5 py-2 rounded-pill text-white">
                      { (status === "sending") && <div className="spinner-border spinner-border-sm align-self-center text-center" role="status">
                        <span className="sr-only">Loading...</span>
                      </div> }
                      { status === "sent" && "Sent :D" }
                      { status === "none" && "Send" }
                    </button>
                  </div>
                </div>
              </form>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  )
}

ContactUs.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(ContactUs, arePropsEqual)
