const SI_SYMBOL = [ "", "k", "M", "G", "T", "P", "E" ]

export const bigNumberFormatter = function (number: number): string {
  var tier = Math.log10(number) / 3 | 0
  if(tier == 0)
    return number.toString()
  var suffix = SI_SYMBOL[tier]
  var scale = Math.pow(10, tier * 3)
  var scaled = number / scale
  return scaled.toFixed(1).replace(".0", "") + suffix
}

export const numberWithCommas = function (x: number): string {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
