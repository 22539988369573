import React, { FunctionComponent, ChangeEventHandler, FormEventHandler, memo, useCallback, useMemo, useState } from "react"
import Fade from "react-reveal/Fade"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import positions from "@/assets/data/careerPositions"
import "./style.scss"
import { apiClient } from "@/helpers/requests/apiClient"

interface Props {
  id: string,
  title?: string,
  desc?: string
}

type SendStatus = "none" | "sending" | "failed" | "sent"

const Submit: FunctionComponent<Props> = function (props) {

  const { id } = props

  const position = useMemo(() => positions[id], [ id ])

  const [ isVisible, setIsVisible ] = useState(false)

  const [ validated, setValidated ] = useState<boolean>(false)
  const [ invalids, setInvalids ] = useState<{ [id: string ]: boolean }>({})
  const [ name, setName ] = useState<string>("")
  const [ email, setEmail ] = useState<string>("")
  const [ portfolio, setPortfolio ] = useState<string>("")
  const [ message, setMessage ] = useState<string>("")
  const [ coverLetter, setCoverLetter ] = useState<File | null>(null)
  const [ resumee, setResumee ] = useState<File | null>(null)
  const [ status, setStatus ] = useState<SendStatus>("none")

  const handleName = useCallback((event) => {
    setInvalids(({ name, ...rest }) => rest)
    setName(event.target.value)
  }, [])

  const handlePortfolio = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setInvalids(({ portfolio, ...rest }) => rest)
    setPortfolio(event.target.value)
  }, [])

  const handleEmail = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setInvalids(({ email, ...rest }) => rest)
    setEmail(event.target.value)
  }, [])

  const handleMessage = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setInvalids(({ message, ...rest }) => rest)
    setMessage(event.target.value)
  }, [])

  const handleResumee = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const file = event.target.files && event.target.files[0]
    if(!file)
      return
    if(file.size / 1024 / 1000 > 3) {
      setInvalids((v) => ({
        ...v,
        resumee: true
      }))
      return
    }
    setInvalids(({ resumee, ...rest }) => rest)
    setResumee(file)
  }, [])

  const handleCoverLetter = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    const file = event.target.files && event.target.files[0]
    if(!file)
      return
    if(file.size / 1024 / 1000 > 3) {
      setInvalids((v) => ({
        ...v,
        coverLetter: true
      }))
      return
    }
    setInvalids(({ coverLetter, ...rest }) => rest)
    setCoverLetter(file)
  }, [])

  const sendApplication = ((event) => {
    event.preventDefault()
    if(status !== "none" || !position)
      return
    setValidated(true)
    if(!resumee || !email || !name) {
      setInvalids({
        resumee: !resumee,
        email: !email,
        name: !name
      })
      return
    }
    setInvalids({})
    setStatus("sending")
    setTimeout(async () => {
      try {
        const reqParams = new FormData()
        reqParams.append("FullName", name)
        reqParams.append("Email", email)
        reqParams.append("Portfolio", portfolio)
        reqParams.append("PositionName", position.title)
        reqParams.append("Curriculum", resumee)
        coverLetter && reqParams.append("CoverLetter", coverLetter)
        await apiClient.post("/api/offers/apply", reqParams)
        setStatus("sent")
        setName("")
        setEmail("")
        setMessage("")
        setPortfolio("")
        setResumee(null)
        setCoverLetter(null)
        setInvalids({})
        setTimeout(() => setStatus("none"), 4000)
      }
      catch (err) {
        setStatus("failed")
        setTimeout(() => setStatus("none"), 4000)
      }
    }, 200)
  }) as FormEventHandler<HTMLFormElement>

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  return (
    <section id="career-submit" className="pt-3 bg-white">
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container p-0">
          <div className="wrapper overflow-hidden col-12 position-relative text-white py-5 px-3 px-sm-4 px-lg-5" style={{
            background: `linear-gradient(0deg, ${position.backgroundColor} 0%, ${position.backgroundColorLight} 100%)`
          }}>
            <Fade when={isVisible} bottom exit={false}>
              <style>{`
                input:focus, input.focus {
                  box-shadow: 0 0 0 2px ${position.backgroundColor} !important;
                }
              `}</style>
              <h1 className="fw-bold display-5 text-center position-relative">{props.title || "Talks about you?"}</h1>
              <h6 className="lead text-center position-relative mt-4 mt-xl-0"><span className="opacity-75">{props.desc || "Perfect! We can't wait to meet you."}</span></h6>
              <div className="row position-relative">
                <div className="col-12 col-md-10 col-lg-8 col-xl-6 mt-5 mx-auto">
                  <form
                    onSubmit={sendApplication}
                    className={`needs-validation${validated ? " was-validated" : ""}`}
                    noValidate
                  >
                    <div className="mb-3">
                      <label htmlFor="inputName" className="form-label text-uppercase fw-bold">Name *</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputName"
                        aria-describedby="name"
                        placeholder="Your name. Simple."
                        onChange={handleName}
                        value={name}
                        required
                      />
                      { invalids.name && <div className="invalid-feedback text-white d-block opacity-75">
                        Please insert your name
                      </div> }
                    </div>
                    <div className="mb-3 needs-validation">
                      <label htmlFor="inputEmail" className="form-label text-uppercase fw-bold">Email *</label>
                      <input
                        type="email"
                        className="form-control"
                        id="inputEmail"
                        aria-describedby="email"
                        placeholder="Where can we answer you?"
                        onChange={handleEmail}
                        value={email}
                        required
                      />
                      { invalids.email && <div className="invalid-feedback text-white d-block opacity-75">
                        Please insert your email
                      </div> }
                    </div>
                    <div className="mb-3">
                      <label htmlFor="inputWebsite" className="form-label text-uppercase fw-bold">Website</label>
                      <input
                        type="text"
                        className="form-control"
                        id="inputWebsite"
                        aria-describedby="website"
                        placeholder="Let yourself be stalked"
                        onChange={handlePortfolio}
                        value={portfolio}
                      />
                    </div>
                    <div className="mb-3 needs-validation">
                      <label htmlFor="inputResume" className="form-label text-uppercase fw-bold">Resumee or CV *</label>
                      <div className="position-relative">
                        <input
                          type="file"
                          className="form-control file-input"
                          id="inputResume"
                          aria-describedby="resumee"
                          placeholder="Please select a file"
                          onChange={handleResumee}
                          required
                          accept=".txt,.doc,.docx,.pdf,.odt"
                        />
                        <div className={`file-label${!resumee ? " empty" : ""} d-flex align-items-center rounded justify-content-start position-absolute top-0 bottom-0 start-0 end-0`}>
                          <p className="m-0">{resumee?.name || "Please select a file"}</p>
                        </div>
                      </div>
                      { invalids.resumee && <div className="invalid-feedback text-white d-block opacity-75">
                        Please add your resumee (.txt, .doc, .docx, .pdf or .odt of max 3 megabytes)
                      </div> }
                    </div>
                    <div className="mb-3">
                      <label htmlFor="inputCover" className="form-label text-uppercase fw-bold">Cover letter</label>
                      <div className="position-relative">
                        <input
                          type="file"
                          className="form-control file-input"
                          id="inputCover"
                          aria-describedby="cotter letter"
                          placeholder="Please select a file"
                          onChange={handleCoverLetter}
                          accept=".txt,.doc,.docx,.pdf,.odt"
                        />
                        <div className={`file-label${!coverLetter ? " empty" : ""} d-flex align-items-center rounded justify-content-start position-absolute top-0 bottom-0 start-0 end-0`}>
                          <p className="m-0">{coverLetter?.name || "Please select a file"}</p>
                        </div>
                      </div>
                      { invalids.resumee && <div className="invalid-feedback text-white d-block opacity-75">
                        Your optional cover letter (.txt, .doc, .docx, .pdf or .odt of max 3 megabytes)
                      </div> }
                    </div>
                    <div className="w-100 d-flex justify-content-center">
                      <button
                        type="submit"
                        className="btn btn-light bg-white mt-5 btn-lg rounded-pill px-5 fw-bold"
                      >
                        { (status === "sending") && <div className="spinner-border spinner-border-sm align-self-center text-center" role="status">
                          <span className="sr-only">Loading...</span>
                        </div> }
                        { status === "sent" && "Thank you!" }
                        { status === "none" && "Submit" }
                      </button>
                    </div>
                    { (status === "failed") && <div className="invalid-feedback text-center mt-3 text-white d-block opacity-75">
                      We are sorry, there was an error. Please try again :(
                    </div> }
                  </form>
                </div>
              </div>
            </Fade>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  )
}

Submit.whyDidYouRender = true

const arePropsEqual = (prevProps: Props, nextProps: Props) => (
  prevProps.id === nextProps.id
)

export default memo(Submit, arePropsEqual)
