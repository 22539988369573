import React, { FunctionComponent, memo } from "react"
import Fade from "react-reveal/Reveal"
import { Entypo } from "react-web-vector-icons"
import Pattern from "@/assets/images/pattern01.svg"
import { HashLink } from "react-router-hash-link"
import "./style.scss"

const Hero: FunctionComponent = function () {
  return (
    <section id="careers-hero" className="pt-5 pt-md-5 pb-5 bg-white position-relative">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <div className="container text-center pt-5 d-flex justify-content-center align-items-center flex-column h-100">
        <Fade bottom>
          <h1 className="fw-bold display-5">Solving hard challenges,<br />with cutting-edge technology.</h1>
          <HashLink to="/tech#tech-iceberg" className="btn btn-primary bg-multicolor border-0 text-white ps-3 pe-4 py-2 mt-5 rounded-pill fw-bold">
            <div className="d-flex align-items-center">
              <Entypo
                name="chevron-down"
                color="white"
                size={25}
              />
              <span className="ps-3">
                Explore the tech
              </span>
            </div>
          </HashLink>
        </Fade>
      </div>
    </section>
  )
}

Hero.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Hero, arePropsEqual)
