import React, { FunctionComponent, memo, useCallback, useEffect, useRef, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Feather } from "react-web-vector-icons"
import MobileNav from "./mobileNav/MobileNav"
import lottie from "lottie-web"
import spicySparksLogo from "@/assets/images/spicySparksLogo_WhiteTransparent.png"
import spicySparksIdLogo from "@/assets/images/spicySparksIdLogo_WhiteTransparent.png"
import spicySparksIdLogo2 from "@/assets/images/spicySparksIdLogo_WhiteTransparent2.png"
import { emitEvent, onEvent, removeEvent } from "@/helpers/events/events"
import "./style.scss"

const hamburgerAnimation = require("@/assets/animation/hamburger.json")

interface Props {
}

const NavBar: FunctionComponent<Props> = function () {

  const navigate = useNavigate()

  const [ mobileNavOpen, setMobileNavOpen ] = useState(false)
  const navTogglerContainer = useRef<any>(null)
  const navTogglerComponent = useRef<any>(null)

  useEffect(() => {
    const openListener = onEvent("setMainMobileNavOpen", openMobileNav)
    return () => {
      removeEvent(openListener as string)
    }
  })

  useEffect(() => {
    if(!navTogglerContainer.current)
      return
    lottie.destroy("main-nav-toggler")
    navTogglerComponent.current = lottie.loadAnimation({
      container: navTogglerContainer.current,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: hamburgerAnimation,
      name: "main-nav-toggler"
    })
    navTogglerComponent.current.setSpeed(1.7)
  }, [ navTogglerContainer.current ])

  const openMobileNav = useCallback((value: boolean | any) => {
    setMobileNavOpen((v) => {
      const newOpen = (typeof value === "boolean") ? value : !v
      if(navTogglerComponent && navTogglerComponent.current) {
        navTogglerComponent.current.playSegments([
          newOpen ? 0 : 60,
          newOpen ? 80 : 140
        ], true)
      }
      document.body.style.overflow = newOpen ? "hidden" : "inherit"
      return newOpen
    })
  }, [ navTogglerComponent.current ])

  const goToHome = useCallback(() => {
    if(mobileNavOpen)
      emitEvent("setMainMobileNavOpen", false)
    navigate("/")
  }, [ mobileNavOpen ])

  return (
    <>
    <nav id="mainNavbar" className={`navbar navbar-expand-md fixed-top p-0 bg-black`}>
      <div className="container h-100 px-4 py-2">

        <button onClick={goToHome} className="btn btn-link p-0">
          <img src={spicySparksLogo} alt="logo" height="50" className="logo position-relative"/>
        </button>

        <button onClick={openMobileNav} className="navbar-toggle btn btn-link p-0 d-md-none menuCollapserButton">
          <div ref={navTogglerContainer} id="main-nav-toggler" />
        </button>

        <div className="collapse navbar-collapse px-4" id="mainNavbar">
          <ul className="nav navbar-nav me-auto">
            <div className="d-block d-md-none pb-3" />
            <li className="nav-item px-2 px-lg-4 text-center">
              <Link className="nav-link text-white" to="/products">Products</Link>
            </li>
            {/* <li className="nav-item px-4">
              <Link className="nav-link text-white" to="/tech">Technologies</Link>
            </li> */}
            <li className="nav-item px-2 px-lg-4 text-center">
              <Link className="nav-link text-white" to="/about">About</Link>
            </li>
            <li className="nav-item px-2 px-lg-4 text-center">
              <Link className="nav-link text-white" to="/tech">Technologies</Link>
            </li>
            <li className="nav-item px-2 px-lg-4 text-center">
              <Link className="nav-link text-white" to="/careers">Careers</Link>
            </li>
            <a className="d-block d-md-none align-self-center" href="https://accounts.spicysparks.com">
              <img src={spicySparksIdLogo} alt="logo" height="35" />
            </a>
            <div className="d-block d-md-none pb-2" />
          </ul>

          <a href="https://accounts.spicysparks.com">
            <img src={spicySparksIdLogo} className="d-none d-lg-block" alt="logo" height="35" />
            <img src={spicySparksIdLogo2} className="d-block d-lg-none" alt="logo" height="35" />
          </a>

          {/*<div className="d-none d-md-block mx-3 br" />

          <a href="https://accounts.spicysparks.com" className="d-none d-md-block search text-decoration-none text-secondary">
            <Feather
              name="user"
              size={25}
            />
          </a>*/}
        </div>

      </div>
    </nav>
    <MobileNav open={mobileNavOpen} />
    </>
  )
}

NavBar.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(NavBar, arePropsEqual)
