import React, { FunctionComponent, memo, useCallback, useEffect, useState } from "react"
import { emitEvent } from "@/helpers/events/events"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import Fade from "react-reveal/Fade"
import spicySparksIdLogo from "@/assets/images/spicySparksIdLogo_WhiteTransparent.png"
import { authUrl } from "@/helpers/constants/constants"

interface Props {
  open: boolean
}

const MobileNav: FunctionComponent<Props> = (props) => {

  const navigate = useNavigate()

  const [ keepClosed, setKeepClosed ] = useState(true)
  const { open } = props

  useEffect(() => {
    if(open && keepClosed)
      setKeepClosed(false)
  }, [ open ])

  const goToProducts = useCallback(() => {
    navigate("/products")
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  const goToAbout = useCallback(() => {
    navigate("/about")
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  const goToTech = useCallback(() => {
    navigate("/tech")
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  const goToId = useCallback(() => {
    navigate("/spicy-sparks-id")
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  const goToCareers = useCallback(() => {
    navigate("/careers")
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  const goToAccount = useCallback(() => {
    navigate(authUrl)
    emitEvent("setMainMobileNavOpen", false)
  }, [])

  return (
    <div
      id="mainNavbarMobile"
      className={`bg-black position-fixed ${keepClosed ? "keepClosed" : (open ? "open" : "close")}`}
    >
      <Fade right cascade when={open} distance="100px" duration={500} delay={100}>
        <ul className="nav flex-column">
          <li className="nav-item">
            <button onClick={goToProducts} className="nav-link text-white display-4 fw-bold">Products</button>
          </li>
          <li className="nav-item">
            <button onClick={goToAbout} className="nav-link text-white display-4 fw-bold mt-2">About</button>
          </li>
          <li className="nav-item">
            <button onClick={goToTech} className="nav-link text-white display-4 fw-bold mt-2">Technologies</button>
          </li>
          <li className="nav-item">
            <button onClick={goToCareers} className="nav-link text-white display-4 fw-bold mt-2 mb-2">Careers</button>
          </li>
          <hr className="ms-3 mt-4" />
          <li className="nav-item">
            <button onClick={goToAccount} className="nav-link text-white display-4 fw-bold mt-2">
              <img src={spicySparksIdLogo} alt="logo" height="65" />
            </button>
          </li>
        </ul>
      </Fade>
    </div>
  )
}

MobileNav.whyDidYouRender = true

const arePropsEqual = (prevProps: Props, nextProps: Props): boolean => (
  prevProps.open === nextProps.open
)

export default memo(MobileNav, arePropsEqual)
