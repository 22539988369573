import axios from "axios"
import { apiUrl } from "@/helpers/constants/constants"
// import { store } from "../../stores/Store"
// import { refreshToken } from "./refreshClient"
// import { ServerError } from "./definitions"

let apiClient = axios.create({
  baseURL: apiUrl,
  responseType: "json",
  headers: {
    "Content-Type": "application/x-www-form-urlencoded" // "application/json"
  }
})

apiClient.interceptors.request.use(
  function (config) {
    /*const token = store.getState().user.get("accessToken")
    if(token)
      config.headers.Authorization = `Bearer ${token}`*/
    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

export { apiClient }
