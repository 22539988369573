import React, { FunctionComponent, memo, useCallback, useEffect, useState } from "react"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import Hexagons1 from "@/assets/images/hexagons01.svg"
import Hexagons2 from "@/assets/images/hexagons02.svg"
import Hexagons3 from "@/assets/images/hexagons03.svg"
import Fade from "react-reveal/Fade"
import Ratio from "react-ratio"
import Wave from "@/assets/images/waves17.png"
import "./style.scss"
import { Parallax, useParallaxController } from "react-scroll-parallax"

const Server: FunctionComponent = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  useEffect(() => {
    updateParallax()
  }, [])

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  return (
    <section id="tech-server" className="pt-5 d-flex align-items-center bg-light">
      <img className="wave-before w-100" src={Wave} />
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container position-relative h-100">
          <div className="row h-100 flex-column-reverse flex-md-row">
            <div className="col-12 col-md-5 col-lg-4 mx-auto position-relative">
              <Ratio ratio={0.7} className="hexagons w-100 position-relative">
                <Parallax className="position-absolute top-0 bottom-0 start-0 end-0" translateY={[ 30, -30 ]}>
                  <img className="position-absolute hexagons3" src={Hexagons3} onLoad={updateParallax} />
                </Parallax>
                <Parallax className="position-absolute top-0 bottom-0 start-0 end-0" translateY={[ 15, -15 ]}>
                  <img className="position-absolute hexagons2" src={Hexagons2} onLoad={updateParallax} />
                </Parallax>
                <img className="position-absolute hexagons1" src={Hexagons1} />
              </Ratio>
            </div>
            <div className="col-12 col-md-6 pb-5 mt-md-5 mt-md-0 h-100 d-flex flex-column align-items-start justify-content-center">
              <Fade bottom when={isVisible}>
                <h1 className="display-5 fw-bold mb-4">Server-side art</h1>
                <p>Our products are used by millions of users every day and the back-ends play a vital role in ensuring quality service.
                The challenges are numerous, such as handling thousands of requests per second or scaling databases very quickly.
                <br /><br />Our back-ends are based on C# microservices, using top-notch technologies that allow incredible performance and the creation of extremely scalable modules.
                All the backend is spread across multiple servers and scales using Kubernetes.
                They interface with larger products via GraphQL, which allows unprecedented versatility.
                For the technology concerning Spicy Sparks accounts, instead, we use RESTful API and NoSQL databases (such as MongoDB), to ensure scalability and very high execution speed.</p>
              </Fade>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </section>
  )
}

Server.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Server, arePropsEqual)
