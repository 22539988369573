import React, { FunctionComponent, memo, useState, useCallback, useEffect } from "react"
import Fade from "react-reveal/Fade"
import Wave from "@/assets/images/waves08.png"
import Pattern from "@/assets/images/pattern06.svg"
import Stars from "@/assets/images/stars02.svg"
import Star1 from "@/assets/images/star01.svg"
import Star2 from "@/assets/images/star02.svg"
import VisibilitySensor from "react-visibility-sensor/visibility-sensor"
import "./style.scss"
import { Parallax, useParallaxController } from "react-scroll-parallax"

interface Props {
}

interface Review {
  user: string,
  message: string
}

const reviews: {
  [ id: number ]: Review
} = {
  0: {
    user: "Lukyanos",
    message: "I can't believe such a free app, keep up the good work! My new fav app!"
  },
  1: {
    user: "djc👑👑👑",
    message: "This app is amazing I have been looking for an app like this for ages!"
  },
  2: {
    user: "umairk786",
    message: "Does everything I want fast and high quality."
  },
  3: {
    user: "el.mxson",
    message: "I’ve never left a review before but I felt this deserved it: this app is just amazing. I would defo recommend this app to everyone"
  },
  4: {
    user: "skinnyde fairy",
    message: "No word better to describe it than the BEST Music App!!"
  },
  5: {
    user: "Unicornfriend2068",
    message: "I love this app because I can make playlists and save songs and all my favorite artists are on this app"
  },
  6: {
    user: "my guy sasuke",
    message: "Gg to the creators a very nice reliable app and the features are nice and well done"
  },
  7: {
    user: "Goodtwell",
    message: "Never found such an amazing app, u look up your favourite songs, add them to playlist or just play them! absolutely amazing 😜😜😜"
  },
  8: {
    user: "bia-alina",
    message: "honestly i love this app"
  },
  9: {
    user: "R34LKiza",
    message: "Can’t say a bad word about this app had it 3 years and it’s incredible couldn’t ask for more"
  },
  10: {
    user: "Never take L's",
    message: "This app is so amazing, the layout is nice and not too all over the place like other apps, the music quality is great and overall a 5 stars from me."
  },
  11: {
    user: "johnybonny__",
    message: "The UI is so smooth and amazing. Really useful app!"
  }
}

const Reviews: FunctionComponent<Props> = function () {

  const [ isVisible, setIsVisible ] = useState(false)

  const visibilityChanged = useCallback((value: boolean) => {
    setIsVisible((currentIsVisible) => {
      if(currentIsVisible)
        return true
      return value
    })
  }, [])

  const parallaxController = useParallaxController()

  const updateParallax = useCallback(() => {
    parallaxController?.update()
  }, [])

  useEffect(() => {
    updateParallax()
  }, [])

  const renderReview = useCallback((id: number, depth: number = 0) => {
    const review = reviews[id]
    if(!review)
      return null
    return (
      <div className={`border-1 card bg-white overflow-hidden review depth-${depth}`}>
        <div className="card-header bg-white w-100 d-flex align-items-center justify-content-between border-0">
          <img className="flex-shrink-0" height={17} src={Stars} onLoad={updateParallax} />
          <span className="m-0 text-secondary">{review.user}</span>
        </div>
        <div className="card-body border-0 text-start pt-1">{review.message}</div>
      </div>
    )
  }, [])

  return (
    <section id="home-reviews" className="position-relative bg-white pt-5">
      <div className="position-absolute top-0 start-0 bottom-0 end-0 background" style={{
        backgroundImage: `url(${Pattern})`
      }} />
      <VisibilitySensor partialVisibility onChange={visibilityChanged}>
        <div className="container-fluid text-center py-5 position-relative">
          <Fade bottom when={isVisible}>
            <h1 className="fw-bold display-5 mt-4">Our beloved<br />reviews.</h1>
          </Fade>
          <div className="d-flex pt-5 flex-column align-items-center">
            <Fade bottom cascade delay={200} when={isVisible}>
              <ul className="stars d-flex flex-row stars justify-content-center align-items-center p-0">
                <li className="d-block mx-1">
                  <img className="flex-shrink-0 w-100 d-block" src={Star1} onLoad={updateParallax} />
                </li>
                <li className="d-block mx-1">
                  <img className="flex-shrink-0 w-100 d-block" src={Star1} onLoad={updateParallax} />
                </li>
                <li className="d-block mx-1">
                  <img className="flex-shrink-0 w-100 d-block" src={Star1} onLoad={updateParallax} />
                </li>
                <li className="d-block mx-1">
                  <img className="flex-shrink-0 w-100 d-block" src={Star1} onLoad={updateParallax} />
                </li>
                <li className="d-block mx-1">
                  <img className="flex-shrink-0 w-100 d-block" src={Star2} onLoad={updateParallax} />
                </li>
              </ul>
            </Fade>
            <Fade bottom delay={350} when={isVisible}>
              <h1 className="rating-text display-1 mt-4 mb-0 text-center fw-bold"><span>4.5</span> / 5</h1>
            </Fade>
          </div>
          <div className="w-100 mb-5 reviews position-relative">
            <div className="position-absolute review4">
              { renderReview(1, 3) }
            </div>
            <div className="position-absolute review1">
              { renderReview(2, 3) }
            </div>
            <div className="position-absolute review3">
              { renderReview(5, 3) }
            </div>
            <div className="position-absolute review4">
              { renderReview(6, 3) }
            </div>
            <div className="position-absolute review5">
              { renderReview(8, 3) }
            </div>
            <Parallax className="w-100 h-100 position-absolute d-none d-md-block" translateY={[ 20, -20 ]}>
              <div className="position-absolute review6">
                { renderReview(9, 2) }
              </div>
              <div className="position-absolute review2">
                { renderReview(10, 2) }
              </div>
              <div className="position-absolute review8">
                { renderReview(11, 2) }
              </div>
            </Parallax>
            <Parallax className="w-100 h-100 position-absolute" translateY={[ 60, -60 ]}>
              <div className="position-absolute review1">
                { renderReview(0) }
              </div>
              <div className="position-absolute review3">
                { renderReview(4) }
              </div>
              <div className="position-absolute review5">
                { renderReview(7) }
              </div>
              <div className="position-absolute review7">
                { renderReview(3) }
              </div>
            </Parallax>
          </div>
        </div>
      </VisibilitySensor>
      <img className="wave-after w-100" src={Wave} />
    </section>
  )
}

Reviews.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(Reviews, arePropsEqual)
