import React, { FunctionComponent, memo } from "react"
import Apps from "./apps/Apps"
import Analytics from "./analytics/Analytics"
import Server from "./server/Server"
import Hero from "./hero/Hero"
import Iceberg from "./iceberg/Iceberg"
import "./style.scss"

const TechPage: FunctionComponent = function () {

  return (
    <div id="techPage">
      <Hero />
      <Iceberg />
      <Apps />
      <Server />
      <Analytics />
    </div>
  )
}

TechPage.whyDidYouRender = true

const arePropsEqual = () => true

export default memo(TechPage, arePropsEqual)
